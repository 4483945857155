import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import { FormGroup } from '@/components/FormGroup';
import { WorkProperty, WorkContent, Work, TimeAlias } from '@/interface';
import {
  dailyreportsFilterOption,
  formKind,
} from '@/store/dailyreports-configure';
import {
  WorkPropertySelect,
  WorkPropertyCascader,
  WorkItemSelect,
  WorkItemCascader,
} from '@/components/form/dailyReport/workProperty';

interface IWorkForm {
  timeAlias: TimeAlias;
  workContents: WorkContent[];
  workContentOptions: Array<CascaderOption>;
  workProperties: WorkProperty[];
  workPropertyOptions: Array<CascaderOption>;
  work: Work | null;
  onChangeProperty: (value: WorkProperty | null) => void;
  onChangeContent: (value: WorkContent | null) => void;
}

const isFormSet = (workProperty: WorkProperty | null): boolean => {
  if (workProperty === null) {
    return false;
  }

  return true;
};

const getRest = (work: Work | null, defaultValue: boolean) => {
  if (work === null) {
    return defaultValue ? 1 : 0;
  }
  return work.includeRest ? 1 : 0;
};

export const WorkFormForTimespan: FC<IWorkForm> = ({
  timeAlias,
  workContents,
  workContentOptions,
  workProperties,
  workPropertyOptions,
  work,
  onChangeProperty,
  onChangeContent,
}) => {
  const formKindConfigure = useRecoilValue(formKind);

  return (
    <>
      <span className="text-base font-bold">
        {timeAlias.name} ({timeAlias.startAt}~{timeAlias.endAt})
      </span>
      <hr className="divide-y" />

      <FormGroup label="案件">
        {formKindConfigure.workProperty === 'select' && (
          <WorkPropertySelect
            workProperties={workProperties}
            newWork={work}
            onChange={(w) => onChangeProperty(w.workProperty)}
          />
        )}
        {formKindConfigure.workProperty === 'cascader' && (
          <WorkPropertyCascader
            delimiter={formKindConfigure.delimiter}
            options={workPropertyOptions}
            workProperties={workProperties}
            newWork={work}
            onChange={(w) => onChangeProperty(w.workProperty)}
          />
        )}
      </FormGroup>
      <FormGroup label="作業">
        {formKindConfigure.workContent === 'select' && (
          <WorkItemSelect
            options={workContents}
            value={work?.workContent}
            recoilValue={dailyreportsFilterOption}
            recoilValueKey="workContentFilterOption"
            placeholder="作業を選択してください..."
            noOptionsMessage={() => '作業が見つかりません'}
            onChange={onChangeContent}
          />
        )}
        {formKindConfigure.workContent === 'cascader' && (
          <WorkItemCascader
            delimiter={formKindConfigure.delimiter}
            options={workContentOptions}
            items={workContents}
            value={work?.workContent}
            title="作業"
            placeholder="作業を選択してください..."
            valueLabelOr="作業を選択できていません"
            onChange={onChangeContent}
          />
        )}
      </FormGroup>
      {isFormSet(work?.workProperty ?? null) && (
        <>
          <input
            type="hidden"
            name="works[][work_property_id]"
            value={work?.workProperty?.id ?? ''}
          />
          <input
            type="hidden"
            name="works[][work_content_id]"
            value={work?.workContent?.id ?? ''}
          />
          <input
            type="hidden"
            name="works[][start_at]"
            readOnly
            value={work?.startAt ?? timeAlias.startAt}
          />
          <input
            type="hidden"
            name="works[][end_at]"
            readOnly
            value={work?.endAt ?? timeAlias.endAt}
          />
          <input
            type="hidden"
            name="works[][include_rest]"
            value={getRest(work, timeAlias.includeRest)}
          />
        </>
      )}
    </>
  );
};
