import React, { FC } from 'react';
import { Portal } from './Portal';

export interface PortalDialogProps {
  children: React.ReactNode | React.ReactElement;
  className?: string;
  el?: string;
}

export const PortalDialog: FC<PortalDialogProps> = (props) => {
  const { children, className, el } = props;

  const modalRoot = document.getElementById('modal-root') || document.body;

  return (
    <Portal rootEl={modalRoot} className={className} el={el}>
      {children}
    </Portal>
  );
};
