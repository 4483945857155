import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import Select from 'react-select';
import { dailyreportsFilterOption } from '@/store/dailyreports-configure';
import { reactSelectStyle } from '@/lib/react-select/reactSelectStyle';
import { WorkProperty, Work } from '@/interface';

type WorkPropertySelectProps = {
  workProperties: Array<WorkProperty>;
  newWork: Work;
  onChange: (work: Work) => void;
};

export const WorkPropertySelect: FC<WorkPropertySelectProps> = ({
  workProperties,
  newWork,
  onChange,
}) => {
  const { projectFilterOption } = useRecoilValue(dailyreportsFilterOption);

  return (
    <Select
      options={workProperties}
      getOptionLabel={(option) => option.name}
      getOptionLabelValue={(option) => option.id}
      isOptionSelected={(target) => newWork?.workProperty?.id === target.id}
      value={newWork?.workProperty}
      onChange={(target) => {
        const workProperty =
          target === null ? null : { id: target.id, name: target.name };
        onChange({
          ...newWork,
          workProperty,
        });
      }}
      placeholder="案件を選択してください..."
      noOptionsMessage={() => '案件が見つかりません'}
      theme={reactSelectStyle}
      isClearable
      isSearchable
      filterOption={projectFilterOption}
    />
  );
};
