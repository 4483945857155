import React, { FC } from 'react';
import { Collapse, List } from 'antd-mobile';
import { newDailyReportsUserDailyReportPath } from '@assets/rails-routes';
import type { User } from '@/interface';
import { I18n } from '@/locales';
import { visit } from '@/lib/turbolink';
import { CheckMark } from '@/components/Icon';

const { Panel } = Collapse;
const ListItem = List.Item;
const defaultDepartmentName = I18n.t('daily_reports.users.index.no_department');

const transition = (userId: number) => () => {
  const url = newDailyReportsUserDailyReportPath({
    userId,
  });
  visit(url);
};

type User2 = User & {
  // eslint-disable-next-line camelcase
  report_status: 0 | 1;
};

type NewProps = {
  users: Record<string, Array<User2>>;
};

const New: FC<NewProps> = ({ users }) => (
  <div className="l-container">
    <Collapse className="reset-collapse-panel-content-color">
      {Object.keys(users).map((departmentName) => {
        const byUsers = users[departmentName];

        return (
          <Panel
            key={departmentName}
            title={I18n.t('daily_reports.users.index.section_title', {
              name: departmentName || defaultDepartmentName,
            })}
          >
            <List>
              {byUsers.map((user) => (
                <ListItem
                  key={user.id}
                  prefix={<CheckMark ok={user.report_status === 1} />}
                  onClick={transition(user.id)}
                >
                  {user.name}
                </ListItem>
              ))}
            </List>
          </Panel>
        );
      })}
    </Collapse>
  </div>
);

export default New;
