import type { Work } from '@/interface';
import { useLocalStorage } from '@rehooks/local-storage';
import { initNewWork } from '@/const';

type UseDailyReportPersist = {
  dailyReportDate: string;
  defaultValue: Array<Work>;
};

export const useDailyReportPersist = ({
  dailyReportDate,
  defaultValue,
}: UseDailyReportPersist) => {
  const DDD = `DRAFT_DR_${dailyReportDate}`;
  const DNDD = `DRAFT_NEWDR_${dailyReportDate}`;

  const works = useLocalStorage<Array<Work>>(DDD, defaultValue);
  const newWork = useLocalStorage<Work>(DNDD, { ...initNewWork, id: null });

  return { works, newWork };
};
