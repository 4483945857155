import type { Config } from './types';
import { defaultFilterConfig } from './config';

const withoutUndefineValue = <T>(value: T): Partial<T> =>
  Object.keys(value).reduce((acc, key) => {
    if (value[key] === undefined) {
      return acc;
    }
    acc[key] = value[key];

    return acc;
  }, {});

export const createFilterConfig = (value: Partial<Config>): Config => {
  const v = withoutUndefineValue(value);

  return {
    ...defaultFilterConfig,
    ...v,
  };
};
