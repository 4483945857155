import axios from 'axios';
import useSWR from 'swr';
import { User } from '@/interface';

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

interface IuseUsers {
  departmentId: number
}

type UseUsers = (args: IuseUsers) => {
  users: Array<User>;
  isUserLoading: boolean;
};

export const useUsers: UseUsers = ({ departmentId }) => {
  const { data, error } = useSWR(`/api/users?department_id=${departmentId}`, fetcher);

  return {
    users: data as User[],
    isUserLoading: !error && !data,
  };
};

type Response = {
  users: Array<User>;
  isLoading: boolean;
};

const queryFetcher = (url: string, target: string) =>
  axios.get(`${url}?${target}`).then((res) => res.data);

export const useUsersWithUnsubmit: (
  querys: { target: string },
  initialData: Array<User>,
) => Response = (querys, initialData) => {
  const qs = () => {
    const usp = new URLSearchParams(querys);
    usp.sort();
    return usp.toString();
  };

  const { data, error } = useSWR(['/api/users/unsubmit', qs()], {
    fallbackData: initialData,
    fetcher: queryFetcher,
  });

  return {
    users: data as User[],
    isLoading: !error && !data,
  };
};
