import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  workPropertiesState,
  workPropertiesWithCascadeState,
  workContentsState,
  workContentsWithCascadeState,
  workDetailsState,
} from '@/store/dailyreports/atom';
import type { Work } from '@/interface';
import { useDailyReport, useDailyReportTypeAControl } from '@/hooks';
import { DailyReportWorksForm } from './DailyReportWorksForm';

export interface IDailyReportWorksFormContainer {
  dailyReportWorks: Work[];
}

// eslint-disable-next-line
export const DailyReportWorksFormContainer = ({
  dailyReportWorks,
}: IDailyReportWorksFormContainer) => {
  const workProperties = useRecoilValue(workPropertiesState);
  const workPropertyOptions = useRecoilValue(workPropertiesWithCascadeState);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);
  const workDetails = useRecoilValue(workDetailsState);
  const { newWorkState, worksState } = useDailyReport({
    defaultValue: dailyReportWorks,
  });
  const { newWork, works, handleChange, handleAdd, handleDelete, addEnable } =
    useDailyReportTypeAControl({
      worksState,
      newWorkState,
    });

  return (
    <DailyReportWorksForm
      workContents={workContents}
      workContentOptions={workContentOptions}
      workProperties={workProperties}
      workPropertyOptions={workPropertyOptions}
      workDetails={workDetails}
      works={works}
      newWork={newWork}
      handleChangeNewWork={handleChange}
      addEnable={addEnable}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
    />
  );
};
