import { MutableSnapshot } from 'recoil';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import {
  dailyreportsConfigureState,
  formKind,
  FormKind,
} from '@/store/dailyreports-configure';
import {
  workPropertiesState,
  workPropertiesWithCascadeState,
  workContentsState,
  workContentsWithCascadeState,
  workDetailsState,
  departmentsState,
  dailyreportState,
  formTypeCMetaDataState,
} from '@/store/dailyreports/atom';
import { createFilterConfig } from '@/lib/react-select/create-filter-config';
import type { WorkProperty, WorkContent, Department } from '@/interface';

export interface InitializeState {
  projectMatchFrom?: 'any' | 'start';
  workContentMatchFrom?: 'any' | 'start';
  workDetailMatchFrom?: 'any' | 'start';
  formKind?: FormKind;
  workProperties: WorkProperty[];
  workPropertiesWithCascade: Array<CascaderOption>;
  workContents: Array<WorkContent>;
  workContentsWithCascade: Array<CascaderOption>;
  workDetails: Array<WorkContent>;
  departments: Array<Department>;
  dailyReportDate: string;
}

type FN = (
  state: InitializeState,
) => (mutableSnapshot: MutableSnapshot) => void;

const createDailyreportsConfigureStateWithDefault = (
  state: InitializeState,
) => {
  const projectSelect = createFilterConfig({
    matchFrom: state.projectMatchFrom,
  });
  const workContentSelect = createFilterConfig({
    matchFrom: state.workContentMatchFrom,
  });
  const workDetailSelect = createFilterConfig({
    matchFrom: state.workDetailMatchFrom,
  });

  return {
    projectSelect,
    workContentSelect,
    workDetailSelect,
  };
};

export const initializeState: FN =
  (state) =>
  ({ set }) => {
    set(
      dailyreportsConfigureState,
      createDailyreportsConfigureStateWithDefault(state),
    );
    set(workPropertiesState, state.workProperties);
    set(workPropertiesWithCascadeState, state.workPropertiesWithCascade);
    set(workContentsState, state.workContents);
    set(workContentsWithCascadeState, state.workContentsWithCascade);
    set(workDetailsState, state.workDetails);
    set(departmentsState, state.departments);
    if (state.dailyReportDate !== undefined) {
      const date = state.dailyReportDate.split('T')[0];
      set(dailyreportState, { date });
    }

    if (state.formKind) {
      set(formKind, state.formKind);
    }
  };

type FNT = (state: {
  departments: Array<Department>;
}) => (mutableSnapshot: MutableSnapshot) => void;

export const initializeThanksState: FNT =
  (state) =>
  ({ set }) => {
    set(departmentsState, state.departments);
  };

export type InitializeFormTypeCState = {
  dailyReportDate: string;
  workContents: Array<WorkContent>;
  workContentsWithCascade: Array<CascaderOption>;
  formKind?: FormKind;
  dummyWorkProperty: WorkProperty;
  breaktimeWorkContent: WorkContent;
};

export const initializeFormTypeCState =
  (state: InitializeFormTypeCState) =>
  ({ set }: MutableSnapshot) => {
    set(workContentsState, state.workContents);
    set(workContentsWithCascadeState, state.workContentsWithCascade);
    set(formTypeCMetaDataState, {
      dummyWorkProperty: state.dummyWorkProperty,
      breaktimeWorkContent: state.breaktimeWorkContent,
    });

    if (state.dailyReportDate !== undefined) {
      const date = state.dailyReportDate.split('T')[0];
      set(dailyreportState, { date });
    }

    if (state.formKind) {
      set(formKind, state.formKind);
    }
  };

  export type InitializeFormTypeDState = InitializeFormTypeCState & {
    departments: Array<Department>;
  };

  export const initializeFormTypeDState =
  (state: InitializeFormTypeDState) =>
  ({ set }: MutableSnapshot) => {
    set(workContentsState, state.workContents);
    set(workContentsWithCascadeState, state.workContentsWithCascade);
    set(formTypeCMetaDataState, {
      dummyWorkProperty: state.dummyWorkProperty,
      breaktimeWorkContent: state.breaktimeWorkContent,
    });

    set(departmentsState, state.departments);

    if (state.dailyReportDate !== undefined) {
      const date = state.dailyReportDate.split('T')[0];
      set(dailyreportState, { date });
    }

    if (state.formKind) {
      set(formKind, state.formKind);
    }
  };
