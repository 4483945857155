import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import { FormGroup } from '@/components/FormGroup';
import { WorkProperty, WorkContent, Work } from '@/interface';
import {
  dailyreportsFilterOption,
  formKind,
} from '@/store/dailyreports-configure';
import {
  WorkPropertySelect,
  WorkPropertyCascader,
  WorkItemSelect,
  WorkItemCascader,
} from '@/components/form/dailyReport/workProperty';

interface IWorkForm {
  workContents: WorkContent[];
  workContentOptions: Array<CascaderOption>;
  workProperties: WorkProperty[];
  workPropertyOptions: Array<CascaderOption>;
  work: Work | null;
  onChangeProperty: (value: WorkProperty | null) => void;
  onChangeContent: (value: WorkContent | null) => void;
  onChangeStartAt: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEndAt: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

export const WorkForm: FC<IWorkForm> = ({
  workContents,
  workContentOptions,
  workProperties,
  workPropertyOptions,
  work,
  onChangeProperty,
  onChangeContent,
  onChangeStartAt,
  onChangeEndAt,
}) => {
  const formKindConfigure = useRecoilValue(formKind);

  return (
    <>
      <FormGroup label="案件" required>
        {formKindConfigure.workProperty === 'select' && (
          <WorkPropertySelect
            workProperties={workProperties}
            newWork={work}
            onChange={(w) => onChangeProperty(w.workProperty)}
          />
        )}
        {formKindConfigure.workProperty === 'cascader' && (
          <WorkPropertyCascader
            delimiter={formKindConfigure.delimiter}
            options={workPropertyOptions}
            workProperties={workProperties}
            newWork={work}
            onChange={(w) => onChangeProperty(w.workProperty)}
          />
        )}
      </FormGroup>
      <FormGroup label="作業" required>
        {formKindConfigure.workContent === 'select' && (
          <WorkItemSelect
            options={workContents}
            value={work?.workContent}
            recoilValue={dailyreportsFilterOption}
            recoilValueKey="workContentFilterOption"
            placeholder="作業を選択してください..."
            noOptionsMessage={() => '作業が見つかりません'}
            onChange={onChangeContent}
          />
        )}
        {formKindConfigure.workContent === 'cascader' && (
          <WorkItemCascader
            delimiter={formKindConfigure.delimiter}
            options={workContentOptions}
            items={workContents}
            value={work?.workContent}
            title="作業"
            placeholder="作業を選択してください..."
            valueLabelOr="作業を選択できていません"
            onChange={onChangeContent}
          />
        )}
      </FormGroup>

      <FormGroup label="開始時刻" required>
        <input
          type="time"
          className="form-control"
          value={work?.startAt ?? ''}
          onChange={onChangeStartAt}
        />
      </FormGroup>
      <FormGroup label="終了時刻" required>
        <input
          type="time"
          className="form-control"
          value={work?.endAt ?? ''}
          onChange={onChangeEndAt}
        />
      </FormGroup>
    </>
  );
};
