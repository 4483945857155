import React, { FC } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  children: React.ReactNode | React.ReactElement;
  rootEl: HTMLElement;
  className?: string;
  el?: string;
}

export const Portal: FC<PortalProps> = (props) => {
  const { children, rootEl, className, el = 'div' } = props;

  const [container] = React.useState(document.createElement(el));

  if (className !== undefined) {
    container.classList.add(...className.split(' '));
  }

  React.useEffect(() => {
    rootEl.appendChild(container);
    return () => {
      rootEl.removeChild(container);
    };
  }, [container, rootEl]);

  return ReactDOM.createPortal(children, container);
};
