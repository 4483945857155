import React from 'react';
import { useRecoilValue } from 'recoil';
import Select from 'react-select';
import { Button } from 'antd-mobile';
import { reactSelectStyle } from '@/lib/react-select/reactSelectStyle';
import { SectionLayout } from '@/components/SectionLayout';
import { FormGroup } from '@/components/FormGroup';
import { DailyReportWorksFormContainer } from '@/components/form/DailyReportViewTypeA';
import { employeeSelectFilterOption } from '@/store/dailyreports-configure';
import {
  useDailyReportRepresentationControl,
  useFetchUnsubmitUsers,
} from '@/hooks';
import type { User, Work } from '@/interface';
import { dailyreportState, useDRDispatch } from '@/store/dailyreports/atom';

type DailyReportRepresentationViewTypeCFormProps = {
  dailyReportWorks: Array<Work>;
  users: Array<User>;
  submitText?: string;
};

export function DailyReportRepresentationViewTypeAForm({
  dailyReportWorks,
  users,
  submitText = '提出',
}: DailyReportRepresentationViewTypeCFormProps) {
  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();

  const filterOption = useRecoilValue(employeeSelectFilterOption);
  const { options, isLoading } = useFetchUnsubmitUsers({ date, users });
  const { selectedUsers, handleChangePerson } =
    useDailyReportRepresentationControl();

  return (
    <>
      <SectionLayout title="1. 作業日時">
        <FormGroup label="日付" required>
          <input
            type="date"
            name="daily_report[date]"
            id="daily_report_date"
            className="form-control"
            required
            value={date}
            onChange={setDate}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="2. 従業員を選択">
        <FormGroup label="従業員" required>
          <Select
            options={options}
            value={selectedUsers}
            onChange={handleChangePerson}
            placeholder="従業員を選択してください..."
            noOptionsMessage={() => '従業員が見つかりません'}
            theme={reactSelectStyle}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            isSearchable
            filterOption={filterOption}
            isLoading={isLoading}
          />
          {selectedUsers.map((user) => (
            <input
              key={user.value}
              type="hidden"
              name="employees[]"
              value={user.value}
            />
          ))}
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="3. 報告内容を編集">
        <DailyReportWorksFormContainer dailyReportWorks={dailyReportWorks} />
      </SectionLayout>

      <div className="submit-container">
        <Button
          block
          type="submit"
          color="primary"
          fill="solid"
          size="large"
          className="flex-40"
        >
          {submitText}
        </Button>
      </div>
    </>
  );
}
