import React, { FC, useMemo, Dispatch } from 'react';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import cx from 'classnames';
import { WorkProperty, WorkContent, Work, TimeAlias } from '@/interface';
import { FormGroup } from '@/components/FormGroup';
import { WorkEntity } from '@/components/WorkEntity';
import { PortalDialog } from '@/components/Portal';
import { Modal } from '@/components/Modal';
import { WorkFormForTimespan } from './WorkFormForTimespan';
import { WorkForm } from './WorkForm';
import { State, Action } from './state';

const I18n = {
  overtime: {
    title: '残業',
    add: '残業時間を追加',
    modal: {
      title: '残業情報を追加してください',
      cancel: 'キャンセル',
      submit: '追加',
    },
  },
};

export interface IDailyReportWorksForm {
  workContents: WorkContent[];
  workContentOptions: Array<CascaderOption>;
  workProperties: WorkProperty[];
  workPropertyOptions: Array<CascaderOption>;
  handleChangeNewWork: (idx: number | null, work: Work) => void;
  handleDelete: (index: number) => void;
  timespanInfo: Array<TimeAlias>;
  state: State;
  dispatch: Dispatch<Action>;
}

const DailyReportTimespanWorksForm: FC<IDailyReportWorksForm> = ({
  workContents,
  workContentOptions,
  workProperties,
  workPropertyOptions,
  handleChangeNewWork,
  handleDelete,
  timespanInfo,
  state,
  dispatch,
}) => {
  const { works } = state;

  const setShowModal = (value) => {
    dispatch({ type: 'MODAL', payload: value });
  };

  const setOvertimeWork = (value) => {
    dispatch({ type: 'OVERTIME', payload: value });
  };

  const handleProperty =
    (idx: number, work: Work | null) => (value: WorkProperty | null) => {
      const workProperty = value === null ? null : value;
      handleChangeNewWork(idx, {
        ...work,
        workProperty,
      });
    };

  const handleContent =
    (idx: number, work: Work) => (value: WorkContent | null) => {
      const workContent = value === null ? null : value;
      handleChangeNewWork(idx, {
        ...work,
        workContent,
      });
    };

  const worksOffset = useMemo(() => timespanInfo.length, [timespanInfo]);

  return (
    <>
      {timespanInfo.map((tinfo, idx) => (
        <section key={idx} className={cx({ 'mt-4': idx !== 0 })}>
          <WorkFormForTimespan
            timeAlias={tinfo}
            workProperties={workProperties}
            workPropertyOptions={workPropertyOptions}
            workContents={workContents}
            workContentOptions={workContentOptions}
            work={works[idx] ?? null}
            onChangeProperty={handleProperty(idx, works[idx])}
            onChangeContent={handleContent(idx, works[idx])}
          />
        </section>
      ))}

      <section className="mt-4">
        <span className="text-base font-bold">{I18n.overtime.title}</span>
        <hr className="divide-y" />

        {works
          .slice(worksOffset)
          .map((work, idx) =>
            work === null ? null : (
              <WorkEntity
                key={work.startAt}
                work={work}
                index={idx + worksOffset}
                handleDelete={handleDelete}
              />
            ),
          )}

        <FormGroup>
          <button
            type="button"
            className="btn-default block"
            onClick={() => setShowModal(true)}
          >
            <i className="fas fa-plus-circle mr-1" />
            {I18n.overtime.add}
          </button>
        </FormGroup>

        <PortalDialog className="fixed top-0 left-0">
          <Modal
            I18n={I18n}
            hidden={!state.showModal}
            onCancel={() => setShowModal(false)}
            onSubmit={() =>
              dispatch({ type: 'ADD_OVERTIME', payload: { works } })
            }
            body={
              <WorkForm
                workProperties={workProperties}
                workPropertyOptions={workPropertyOptions}
                workContents={workContents}
                workContentOptions={workContentOptions}
                work={state.overtimeWork}
                onChangeProperty={(workProperty) =>
                  setOvertimeWork({ ...state.overtimeWork, workProperty })
                }
                onChangeContent={(workContent) =>
                  setOvertimeWork({ ...state.overtimeWork, workContent })
                }
                onChangeStartAt={(ev) =>
                  setOvertimeWork({
                    ...state.overtimeWork,
                    startAt: ev.target.value,
                  })
                }
                onChangeEndAt={(ev) =>
                  setOvertimeWork({
                    ...state.overtimeWork,
                    endAt: ev.target.value,
                  })
                }
              />
            }
          />
        </PortalDialog>
      </section>
    </>
  );
};

export default DailyReportTimespanWorksForm;
