import { DateTime } from 'luxon';

type Period = {
  startAt: string;
  endAt: string;
};

const conv = (time): DateTime => DateTime.fromSQL(time, { zone: 'Asia/Tokyo' });

/**
 * 開始時刻と終了時刻がプラスであることを検証
 * @module validators
 * @param {Period} period - 時刻
 * @return {boolean} - return true if endAtがstartAtよりも後ろにいる
 */
export const isTimePeriodValid = (period: Period): boolean => {
  const startAt = conv(period.startAt);
  const endAt = conv(period.endAt);
  if (startAt < endAt) {
    return true;
  }

  return false;
};

/**
 * 重複した期間を検証する
 * @module validators
 * @param {Array<Period>} periods - 追加済み期間
 * @param {Period} period - 追加期間
 * @return {[boolean, Period | null]} - return [true, null] if not duplicated,
 * but invalid occurs return [false, reason period]
 * https://yucatio.hatenablog.com/entry/2018/08/16/175914
 */
export const isNoOverlapValid = (
  periods: Array<Period>,
  newPeriod: Period,
): [boolean, Period | null] => {
  if (periods.length === 0) {
    return [true, null];
  }

  const newStartAt = conv(newPeriod.startAt);
  const newEndAt = conv(newPeriod.endAt);

  const invalids = periods.filter((period) => {
    const startAt = conv(period.startAt);
    const endAt = conv(period.endAt);

    if (startAt < newEndAt && newStartAt < endAt) {
      return true;
    }

    return false;
  });

  if (invalids.length > 0) {
    return [false, invalids[0]];
  }

  return [true, null];
};
