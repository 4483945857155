import type { NewWorkWithWorkingForm } from '@/interface';

export const initNewWork = {
  workProperty: null,
  workContent: null,
  workDetail: null,
  startAt: '',
  endAt: '',
  includeRest: false,
};

export const initNewWorkWithWorkingForm: NewWorkWithWorkingForm = {
  object: 'newWorkWithWorkingForm',
  workContent: null,
  startAt: '',
};
