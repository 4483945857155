import validate from 'validate.js';
import { isValidWork } from '@/utils/isValidWork';
import { isTimePeriodValid, isNoOverlapValid } from '@/validators';
import { Work } from '@/interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Action = { type: string; payload?: any };

export interface State {
  showModal: boolean;
  overtimeWork: Work | null;
  works: Array<Work>;
}

const constraints = {
  workProperty: {
    presence: true,
  },
  workContent: {
    presence: true,
  },
  startAt: {
    presence: true,
  },
  endAt: {
    presence: true,
  },
};

const nonNull = <T>(items: Array<T>) => items.filter((item) => item !== null);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'MODAL':
      return { ...state, showModal: action.payload };
    case 'OVERTIME':
      return { ...state, overtimeWork: action.payload };
    case 'ADD_OVERTIME': {
      const { works, overtimeWork } = state;
      const ret = validate.validate(overtimeWork, constraints);
      if (ret !== undefined) {
        return { ...state };
      }

      const x = isValidWork(overtimeWork);
      const y = isTimePeriodValid(overtimeWork);
      const z = isNoOverlapValid(nonNull(works), overtimeWork);
      if (x && y && z[0]) {
        return {
          ...state,
          showModal: false,
          overtimeWork: null,
          works: [...works, overtimeWork],
        };
      }
      return { ...state };
    }
    case 'SET_WORKS':
      return { ...state, works: action.payload.works };
    default:
      break;
  }
  return { ...state };
};
