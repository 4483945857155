import React, { ReactElement } from 'react';
import { Tag } from 'antd-mobile';
import { I18n } from '@/locales';

type CheckMarkProps = { ok?: boolean };

export const CheckMark = ({
  ok,
}: CheckMarkProps): ReactElement<CheckMarkProps> => (
  <Tag color={ok ? 'success' : 'default'}>
    {ok
      ? I18n.t('helpers.state.dr.submitted')
      : I18n.t('helpers.state.dr.not_submitted')}
  </Tag>
);
