import React, {
  useState,
  forwardRef,
  useEffect,
  useCallback,
  MutableRefObject,
  ChangeEvent,
} from 'react';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import type { Work, NewWorkWithWorking, WorkContent } from '@/interface';
import { isWork } from '@/utils';
import { FormGroup } from '@/components/FormGroup';
import { WorkItemCascader } from '@/components/form/dailyReport/workProperty';

type WorkFormTypeCProps = {
  delimiter: string;
  workContentOptions: Array<CascaderOption>;
  workContents: Array<WorkContent>;
  work: Work | NewWorkWithWorking;
};

type WorkFormTypeCRefObject = {
  getWork: () => Work | NewWorkWithWorking;
};

const useWorkFormTypeC = ({ work }: { work: Work | NewWorkWithWorking }) => {
  const [newWork, setNewWork] = useState(work);

  const handleChange = useCallback(
    (value: { id: number; name: string } | null) => {
      setNewWork((prev) => ({
        ...prev,
        workContent: value as WorkContent,
      }));
    },
    [setNewWork],
  );

  const handleChangeStartAt = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      if (event?.type === 'change' && event.target && event.target.value) {
        setNewWork((prev) => ({
          ...prev,
          startAt: event.target.value,
        }));
      }
    },
    [setNewWork],
  );

  const handleChangeEndAt = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      if (event?.type === 'change' && event?.target?.value) {
        setNewWork((prev) => ({
          ...prev,
          endAt: event.target.value,
        }));
      }
    },
    [setNewWork],
  );

  return { newWork, handleChange, handleChangeStartAt, handleChangeEndAt };
};

export const WorkFormTypeC = forwardRef<
  WorkFormTypeCRefObject,
  WorkFormTypeCProps
>((props, ref) => {
  const { delimiter, workContentOptions, workContents, work } = props;
  const { newWork, handleChange, handleChangeStartAt, handleChangeEndAt } =
    useWorkFormTypeC({ work });

  useEffect(() => {
    if (!ref) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    (ref as MutableRefObject<WorkFormTypeCRefObject>).current = {
      getWork: () => newWork,
    };
  }, [ref, newWork]);

  return (
    <>
      {!(newWork?.type === 'breaktime') && (
        <FormGroup label="作業" required>
          <WorkItemCascader
            delimiter={delimiter}
            options={workContentOptions}
            items={workContents}
            value={newWork?.workContent}
            title="作業"
            placeholder="作業を選択してください..."
            valueLabelOr="作業を選択できていません"
            onChange={handleChange}
          />
        </FormGroup>
      )}

      <FormGroup label="開始時刻" required>
        <input
          type="time"
          className="form-control"
          value={newWork?.startAt}
          onChange={handleChangeStartAt}
        />
      </FormGroup>

      {isWork(newWork) && (
        <FormGroup label="終了時刻" required>
          <input
            type="time"
            className="form-control"
            value={newWork?.endAt}
            onChange={handleChangeEndAt}
          />
        </FormGroup>
      )}
    </>
  );
});
