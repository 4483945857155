import React, { FC } from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { ConfigProvider } from 'antd-mobile';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { useDailyReportTypeDPersist } from 'hooks/dailyReport/useDailyReportTypeDPersist'
import { DailyReportViewTypeDForm } from '@/components/form/DailyReportViewTypeD';
import { initializeFormTypeDState } from '@/store/initializeState/dailyreports';
import type { FormTypeDProps } from '@/types/pages';
import { dailyreportState, useDRDispatch } from '@/store/dailyreports/atom';
import type { Arigatona } from 'interface';

const InnerNew = ({ userId, dailyReportArigatona }: { userId: string, dailyReportArigatona: Arigatona }) => {
  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();
  const { worksState, workingWorkState, newWorkState } =
    useDailyReportTypeDPersist({
      userId,
      dailyReportDate: date,
      defaultValue: [],
    });

  return (
    <DailyReportViewTypeDForm
      date={date}
      setDate={setDate}
      worksState={worksState}
      workingWorkState={workingWorkState}
      newWorkState={newWorkState}
      dailyReportArigatona={dailyReportArigatona}
    />
  );
};

const NewTypeD: FC<FormTypeDProps> = (props) => {
  const {
    userId,
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
    departments,
    dailyReportArigatona
  } = props;

  const initState = initializeFormTypeDState({
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
    departments,
  });

  return (
    <ConfigProvider locale={jaJp}>
      <RecoilRoot initializeState={initState}>
        <InnerNew userId={userId} dailyReportArigatona={dailyReportArigatona}/>
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default NewTypeD;
