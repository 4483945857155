import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import DailyReportArigatonaForm from '@/components/DailyReportArigatonaForm';
import { initializeThanksState } from '@/store/initializeState/dailyreports';
import type { FormThanksProps } from '@/types/pages';

const FormEditThanks: FC<FormThanksProps> = (props) => {
  const { departments, dailyReportArigatona } = props;

  const initState = initializeThanksState({
    departments,
  });

  return (
    <RecoilRoot initializeState={initState}>
      <DailyReportArigatonaForm dailyReportArigatona={dailyReportArigatona} />
    </RecoilRoot>
  );
};

export default FormEditThanks;
