import React, { FC, useMemo } from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { ConfigProvider } from 'antd-mobile';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { useDailyReportTypeD } from '@/hooks';
import { DailyReportViewTypeDForm } from '@/components/form/DailyReportViewTypeD';
import { initializeFormTypeDState } from '@/store/initializeState/dailyreports';
import type { FormTypeDProps } from '@/types/pages';
import { dailyreportState } from '@/store/dailyreports/atom';
import type { Work, Arigatona } from '@/interface';

const InnerEdit = ({ works, dailyReportArigatona }: { works: Array<Work>, dailyReportArigatona: Arigatona}) => {
  const { date } = useRecoilValue(dailyreportState);

  const { worksState, workingWorkState, newWorkState } = useDailyReportTypeD({
    defaultValue: works,
  });

  return (
    <DailyReportViewTypeDForm
      date={date}
      setDate={() => undefined}
      worksState={worksState}
      workingWorkState={workingWorkState}
      newWorkState={newWorkState}
      submitText="更新"
      dailyReportArigatona={dailyReportArigatona}
    />
  );
};

const EditTypeD: FC<FormTypeDProps> = (props) => {
  const {
    dailyReportDate,
    dailyReportWorks,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
    dailyReportArigatona,
    departments
  } = props;

  const initState = initializeFormTypeDState({
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
    departments,
  });

  const works = useMemo(
    () =>
      dailyReportWorks.map((w) => {
        if (w.workContent.id === breaktimeWorkContent.id) {
          return { ...w, type: 'breaktime' };
        }
        return w;
      }),
    [dailyReportWorks, breaktimeWorkContent.id],
  );

  return (
    <ConfigProvider locale={jaJp}>
      <RecoilRoot initializeState={initState}>
        <InnerEdit works={works} dailyReportArigatona={dailyReportArigatona}/>
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default EditTypeD;
