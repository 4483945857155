import { Option, None, Some } from 'ts-results';
import type { CascaderValue } from 'antd-mobile/es/components/cascader';

interface ID {
  id: number;
}

const isId = (workProperty: ID, target: CascaderValue): boolean => {
  if (workProperty.id.toString() === target) {
    return true;
  }

  return false;
};

export const findCascaderValue = <T extends ID>(
  items: Array<T>,
  target: Array<CascaderValue>,
): Option<T> => {
  const lastValue = target[target.length - 1];

  const ret = items.find((item) => isId(item, lastValue));
  if (ret !== undefined) {
    return Some(ret);
  }

  return None;
};

export const makeCascaderDefaultValue = (
  workItem: { id: number; name: string } | null,
  delimiter: string,
): Array<string> => {
  const defaultValue =
    workItem?.name.split(delimiter).map((wi) => wi.trim()) ?? [];
  if (defaultValue.length !== 0) {
    defaultValue[defaultValue.length - 1] = `${workItem.id}`;
  }

  return defaultValue;
};
