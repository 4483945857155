import React, { FC } from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { ConfigProvider } from 'antd-mobile';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { useDailyReportTypeCPersist } from '@/hooks';
import { DailyReportViewTypeCForm } from '@/components/form/DailyReportViewTypeC';
import { initializeFormTypeCState } from '@/store/initializeState/dailyreports';
import type { FormTypeCProps } from '@/types/pages';
import { dailyreportState, useDRDispatch } from '@/store/dailyreports/atom';

const InnerNew = ({ userId }: { userId: string }) => {
  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();
  const { worksState, workingWorkState, newWorkState } =
    useDailyReportTypeCPersist({
      userId,
      dailyReportDate: date,
      defaultValue: [],
    });

  return (
    <DailyReportViewTypeCForm
      date={date}
      setDate={setDate}
      worksState={worksState}
      workingWorkState={workingWorkState}
      newWorkState={newWorkState}
    />
  );
};

const NewTypeC: FC<FormTypeCProps> = (props) => {
  const {
    userId,
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
  } = props;

  const initState = initializeFormTypeCState({
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
  });

  return (
    <ConfigProvider locale={jaJp}>
      <RecoilRoot initializeState={initState}>
        <InnerNew userId={userId} />
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default NewTypeC;
