import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import DailyReportsRepresentationContainer from '@/components/DailyReportsRepresentation/DailyReportsRepresentationContainer';
import { initializeState } from '@/store/initializeState/dailyreports';
import type { FormRepresentationProps } from '@/types/pages';

const NewTypeB: FC<FormRepresentationProps> = (props) => {
  const {
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    dailyReportDate,
    formKind,
    ...other
  } = props;

  const initState = initializeState({
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    dailyReportDate,
    workDetails: [],
    departments: [],
    formKind,
  });

  return (
    <RecoilRoot initializeState={initState}>
      <DailyReportsRepresentationContainer {...other} />
    </RecoilRoot>
  );
};

export default NewTypeB;
