import React, { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Dialog } from 'antd-mobile';
import type { Work, NewWorkWithWorking, DispatchReturn } from '@/interface';
import { isNewWorkWithWorking } from '@/utils';
import { WorkFormTypeD } from '@/components/form/DailyReportViewTypeD/WorkFormTypeD';
import {
  workContentsState,
  workContentsWithCascadeState,
} from '@/store/dailyreports/atom';
import { formKind } from '@/store/dailyreports-configure';

type UseDailyReportTypeDDialogControl = {
  workingWorkState: DispatchReturn<NewWorkWithWorking | null>;
};

export const useDailyReportTypeDWorkingEditDialogControl = ({
  workingWorkState,
}: UseDailyReportTypeDDialogControl): { handleWorkingEdit: (work: NewWorkWithWorking) => void } => {
  const [, setWorkingWork] = workingWorkState;
  const formKindConfigure = useRecoilValue(formKind);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);

  const formRef = useRef<{ getWork: () => Work | NewWorkWithWorking }>();

  const handleWorkingEdit = useCallback(
    (work: NewWorkWithWorking) => {
      Dialog.confirm({
        content: (
          <WorkFormTypeD
            delimiter={formKindConfigure.delimiter}
            workContentOptions={workContentOptions}
            workContents={workContents}
            work={work}
            ref={formRef}
          />
        ),
        cancelText: 'キャンセル',
        confirmText: '変更',
        onConfirm: () => {
          const v = formRef.current.getWork();
          if (isNewWorkWithWorking(v)) {
            setWorkingWork(v);
          }
        },
      });
    },
    [setWorkingWork, formKindConfigure.delimiter, workContentOptions, workContents],
  );

  return {
    handleWorkingEdit,
  };
};
