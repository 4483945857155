import React from 'react';
import {
  ResponsiveContainer,
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Brush,
} from 'recharts';
import chroma from 'chroma-js';

export interface WorkContent {
  name: string;
}

// must be name key
export type GraphData = { [key in string]: number };

export type WorkTimePerProjectBarGraphProps = {
  workContents: Array<WorkContent>;
  graphData: Array<GraphData>;
};

const WorkTimePerProjectBarGraph: React.FC<WorkTimePerProjectBarGraphProps> = (props) => {
  const {
    workContents,
    graphData,
  } = props;

  const colors = chroma.scale('Spectral').colors(workContents.length);

  const onFmt = (value) => (value || 0);

  // flatten values
  const data = graphData.map((row) => {
    const v = row.values.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    return {
      name: row.name,
      ...v,
    };
  });

  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <BarChart
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip filterNull={false} itemStyle={{ color: '#000000' }} formatter={onFmt} />
        <XAxis dataKey="name" />
        <YAxis unit="h" />
        {
          workContents.map((contentName, index) => (
            <Bar
              key={contentName.name}
              dataKey={contentName.name}
              stackId="a"
              fill={colors[index]}
              unit="h"
            />
          ))
        }
        <Brush
          dataKey="name"
          stroke="#8884d8"
          startIndex={0}
          endIndex={1}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WorkTimePerProjectBarGraph;
