import { Work, NewWorkWithWorking } from '@/interface';

export const isValidWork = (work: Work): boolean => {
  if (!work.workContent || work.workContent.id.toString() === '0') {
    return false;
  }
  if (!work.workProperty || work.workProperty.id.toString() === '0') {
    return false;
  }
  if (!work.startAt) {
    return false;
  }
  if (!work.endAt) {
    return false;
  }

  return true;
};

export const isValidWorkWithWorking = (work: NewWorkWithWorking): boolean => {
  if (!work.workContent || work.workContent.id.toString() === '0') {
    return false;
  }
  if (!work.startAt) {
    return false;
  }

  return true;
};
