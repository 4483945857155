import React, { FC, ChangeEvent, useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import Select from 'react-select';
import { Button, Space } from 'antd-mobile';
import {
  useDailyReportRepresentTypeCControl,
  useDailyReportTypeCWorkEditDialogControl,
} from '@/hooks';
import { useUsersWithUnsubmit } from '@/hooks/api';
import { Work, DispatchReturn, User } from '@/interface';
import { reactSelectStyle } from '@/lib/react-select/reactSelectStyle';
import {
  formKind,
  employeeSelectFilterOption,
} from '@/store/dailyreports-configure';
import {
  workContentsState,
  workContentsWithCascadeState,
} from '@/store/dailyreports/atom';
import { SectionLayout } from '@/components/SectionLayout';
import { FormGroup } from '@/components/FormGroup';
import { WorkItemCascader } from '@/components/form/dailyReport/workProperty';
import { WorkContentList } from './WorkContentList';
import { WorkEntityListWithHidden } from './WorkEntityListWithHidden';
import { ThunksWithHidden } from './ThunksWithHidden';

type DailyReportRepresentationViewTypeCFormProps = {
  date: string;
  setDate: (event: ChangeEvent<HTMLInputElement>) => void;
  newWorkState: DispatchReturn<Work>;
  worksState: DispatchReturn<Array<Work>>;
  users: Array<User>;
  submitText?: string;
};

export const DailyReportRepresentationViewTypeCForm: FC<
  DailyReportRepresentationViewTypeCFormProps
> = ({
  date,
  setDate,
  worksState,
  newWorkState,
  users,
  submitText = '提出',
}) => {
  const formKindConfigure = useRecoilValue(formKind);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);
  const filterOption = useRecoilValue(employeeSelectFilterOption);

  const { users: fetchUsers, isLoading } = useUsersWithUnsubmit(
    { target: date },
    users,
  );
  const [selectedUsers, setSelectedUsers] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const handleChangePerson = (
    value: Array<{ label: string; value: string }>,
  ) => {
    setSelectedUsers(value);
  };
  const options = useMemo<Array<{ label: string; value: string }>>(
    () =>
      fetchUsers.map((user) => ({
        label: user.name,
        value: user.id.toString(),
      })),
    [fetchUsers],
  );

  const {
    newWork,
    works,
    handleChange,
    handleChangeStartAt,
    handleChangeEndAt,
    handleAdd,
    handleDelete,
    handleClickBreaktime,
  } = useDailyReportRepresentTypeCControl({
    worksState,
    newWorkState,
  });
  const { handleWorkEdit } = useDailyReportTypeCWorkEditDialogControl({
    worksState,
  });

  return (
    <>
      <SectionLayout title="1. 作業日時">
        <FormGroup label="日付" required>
          <input
            type="date"
            name="daily_report[date]"
            id="daily_report_date"
            className="form-control"
            required
            value={date}
            onChange={setDate}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="2. 従業員を選択">
        <FormGroup label="従業員" required>
          <Select
            options={options}
            value={selectedUsers}
            onChange={handleChangePerson}
            placeholder="従業員を選択してください..."
            noOptionsMessage={() => '従業員が見つかりません'}
            theme={reactSelectStyle}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            isSearchable
            filterOption={filterOption}
            isLoading={isLoading}
          />
          {selectedUsers.map((user) => (
            <input
              key={user.value}
              type="hidden"
              name="employees[]"
              value={user.value}
            />
          ))}
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="3. 報告内容を編集">
        <FormGroup label="作業" required>
          <div className="flex gap-3">
            <WorkItemCascader
              delimiter={formKindConfigure.delimiter}
              options={workContentOptions}
              items={workContents}
              value={newWork?.workContent}
              title="作業"
              placeholder="作業を選択してください..."
              valueLabelOr="作業を選択できていません"
              onChange={handleChange}
            />
            <Button
              style={{ flexShrink: 0 }}
              color="success"
              fill="outline"
              onClick={handleClickBreaktime}
            >
              休憩
            </Button>
          </div>
        </FormGroup>
        <Space block className="item-flex-1" style={{ marginTop: '1rem' }}>
          <FormGroup label="開始時刻" required>
            <input
              type="time"
              className="form-control"
              value={newWork?.startAt}
              onChange={handleChangeStartAt}
            />
          </FormGroup>
          <FormGroup label="終了時刻" required>
            <input
              type="time"
              className="form-control"
              value={newWork?.endAt}
              onChange={handleChangeEndAt}
            />
          </FormGroup>
        </Space>

        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            color="primary"
            fill="outline"
            size="large"
            onClick={handleAdd}
          >
            追加
          </Button>
        </div>

        <WorkContentList
          works={works}
          onClickEdit={handleWorkEdit}
          onClickDelete={handleDelete}
        />
      </SectionLayout>

      <WorkEntityListWithHidden works={works} />
      <ThunksWithHidden />

      <div className="submit-container">
        <Button
          block
          type="submit"
          color="primary"
          fill="solid"
          size="large"
          className="flex-40"
        >
          {submitText}
        </Button>
      </div>
    </>
  );
};
