import React, { FC, useState } from 'react';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { Cascader as AntCascader, ConfigProvider, Button } from 'antd-mobile';
import type {
  CascaderOption,
  CascaderValue,
  CascaderValueExtend,
} from 'antd-mobile/es/components/cascader';
import cx from 'classnames';
import { getValueLabel } from '@/utils/getValueLabel';

jaJp.common.confirm = '決定';

type CascaderProps = {
  value: Array<CascaderValue>;
  valueLabel: string | null;
  valueLabelOr?: string;
  options: Array<CascaderOption>;
  onConfirm: (value: Array<CascaderValue>, extend: CascaderValueExtend) => void;
  title?: string;
  placeholder?: string;
};

export const Cascader: FC<CascaderProps> = ({
  value,
  valueLabel,
  valueLabelOr = 'not selected yet...',
  options,
  onConfirm,
  title,
  placeholder = 'select...',
}) => {
  const [visiblity, setVisibility] = useState(false);
  const isEmpty = value.length === 0;
  const selectLabel = getValueLabel(isEmpty, placeholder, valueLabel);

  return (
    <ConfigProvider locale={jaJp}>
      <button
        type="button"
        className={cx('form-control text-left btn', {
          placeholder: isEmpty,
        })}
        onClick={() => setVisibility(true)}
      >
        <span className={cx('px-2', { 'text-red-500': selectLabel.none })}>
          {selectLabel.unwrapOr(valueLabelOr)}
        </span>
      </button>
      <AntCascader
        className="cascader"
        visible={visiblity}
        options={options}
        value={value}
        onClose={() => setVisibility(false)}
        onConfirm={onConfirm}
        title={title}
        confirmText={
          <Button color="primary" size="large">
            {jaJp.common.confirm}
          </Button>
        }
      />
    </ConfigProvider>
  );
};
