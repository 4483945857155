import React, { FC, ReactNode } from 'react';

interface IFormGroup {
  label?: string
  required?: boolean
  children: React.ReactNode
}

export const FormGroup: FC<IFormGroup> = ({ label, children, required }) => (
  <div className="form-group">
    <label className="form-group-inner">
      {
        label
        && <div className={`form-label ${required ? 'required' : ''}`}>{label}</div>
      }
      {children}
    </label>
  </div>
);

interface IFormGroupToggle {
  label?: string
  required?: boolean
  children: ReactNode
}

export const FormGroupToggle: FC<IFormGroupToggle> = ({ label, children, required = false }) => (
  <div className="form-group toggle">
    <label className="form-group-inner">
      {children}
      {
        label
        && <div className={`form-label ${required ? 'required' : ''}`}>{label}</div>
      }
    </label>
  </div>
);
