import React, { FC, useRef, ChangeEvent } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, AutoCenter, Dialog } from 'antd-mobile';
import type {
  Work,
  NewWorkWithWorkingForm,
  NewWorkWithWorking,
  DispatchReturn,
} from '@/interface';
import {
  useDailyReportTypeDControl,
  useDailyReportTypeDWorkingEditDialogControl,
  useDailyReportTypeDWorkEditDialogControl,
} from '@/hooks';
import { SectionLayout } from '@/components/SectionLayout';
import { FormGroup } from '@/components/FormGroup';
import { WorkItemCascader } from '@/components/form/dailyReport/workProperty';
import {
  workContentsState,
  workContentsWithCascadeState,
} from '@/store/dailyreports/atom';
import { formKind } from '@/store/dailyreports-configure';
import { Arigatona } from '@/interface';
import DailyReportArigatonaForm from '@/components/DailyReportArigatonaForm';
import { WorkingWorkContent } from "./WorkingWorkContent"
import { WorkContentList } from "./WorkContentList"
import { WorkEntityListWithHidden } from '../DailyReportViewTypeC/WorkEntityListWithHidden';



const dialogMessage = `本日の日報を提出しますか？
変更する方は編集画面からおこなってください`;

type DailyReportViewTypeDFormProps = {
  date: string;
  setDate: (event: ChangeEvent<HTMLInputElement>) => void;
  newWorkState: DispatchReturn<NewWorkWithWorkingForm>;
  workingWorkState: DispatchReturn<NewWorkWithWorking>;
  worksState: DispatchReturn<Array<Work>>;
  submitText?: string;
  dailyReportArigatona: Arigatona;
};

export const DailyReportViewTypeDForm: FC<DailyReportViewTypeDFormProps> = ({
  date,
  setDate,
  worksState,
  workingWorkState,
  newWorkState,
  submitText = '提出',
  dailyReportArigatona,
}) => {
  const submitBtnRef = useRef<HTMLInputElement>();
  const formKindConfigure = useRecoilValue(formKind);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);
  const {
    newWork,
    workingWork,
    works,
    handleChange,
    handleChangeStartAt,
    handleAdd,
    handleDelete,
    handleClickBreaktime,
    handleSubmit,
    handleWorkingDelete,
    handleQuantityChange,
  } = useDailyReportTypeDControl({
    worksState,
    workingWorkState,
    newWorkState,
  });
  const { handleWorkingEdit } = useDailyReportTypeDWorkingEditDialogControl({
    workingWorkState,
  });

  const { handleWorkEdit } = useDailyReportTypeDWorkEditDialogControl({
    worksState,
  });

  const handleSubmitWrapper = () => {
    handleSubmit();
    Dialog.confirm({
      content: dialogMessage,
      cancelText: 'いいえ',
      confirmText: 'はい',
      onConfirm: async () => {
        submitBtnRef.current.click();
      },
    });
  };

  return (
    <>
      <SectionLayout title="1. 作業日時">
        <FormGroup label="日付" required>
          <input
            type="date"
            name="daily_report[date]"
            id="daily_report_date"
            className="form-control"
            required
            value={date}
            onChange={setDate}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="2. 報告内容を編集">
        <FormGroup label="作業" required>
          <WorkItemCascader
            delimiter={formKindConfigure.delimiter}
            options={workContentOptions}
            items={workContents}
            value={newWork?.workContent}
            title="作業"
            placeholder="作業を選択してください..."
            valueLabelOr="作業を選択できていません"
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup label="開始時刻" required>
          <div className="flex gap-3">
            <input
              type="time"
              className="form-control"
              value={newWork?.startAt}
              onChange={handleChangeStartAt}
            />
            <Button
              color="primary"
              fill="solid"
              className="flex-40"
              onClick={handleAdd}
            >
              開始
            </Button>
          </div>
        </FormGroup>

        <AutoCenter style={{ marginTop: '1rem' }}>
          <Button color="success" fill="outline" onClick={handleClickBreaktime}>
            休憩開始
          </Button>
        </AutoCenter>

        <WorkingWorkContent
          work={workingWork}
          onClickEdit={handleWorkingEdit}
          onClickDelete={handleWorkingDelete}
          onQuantityChange={handleQuantityChange}
          />

        <WorkContentList
          works={works}
          onClickEdit={handleWorkEdit}
          onClickDelete={handleDelete}
        />

        <WorkEntityListWithHidden works={works} />
      </SectionLayout>

      <SectionLayout title="3. ありがとうを送る">
        <FormGroup>
          <DailyReportArigatonaForm
            dailyReportArigatona={dailyReportArigatona}
          />
        </FormGroup>
        <FormGroup>
          <textarea
            name="arigatona[comment]"
            id="arigatona_comment"
            className="form-control"
            rows={3}
            defaultValue={dailyReportArigatona?.comment}
          />
        </FormGroup>
      </SectionLayout>

      <div className="submit-container">
        <input type="submit" className="hidden" ref={submitBtnRef} />
        <Button
          block
          color="primary"
          fill="solid"
          size="large"
          className="flex-40"
          onClick={handleSubmitWrapper}
        >
          {submitText}
        </Button>
      </div>
    </>
  );
};
