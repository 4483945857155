import { atom, useRecoilCallback } from 'recoil';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import type { WorkProperty, WorkContent, Department } from '@/interface';

export const workPropertiesState = atom<Array<WorkProperty>>({
  key: 'atom/dr/workProperties',
  default: [],
});

export const workPropertiesWithCascadeState = atom<Array<CascaderOption>>({
  key: 'atom/dr/workPropertiesWithCascade',
  default: [],
});

export const workContentsState = atom<Array<WorkContent>>({
  key: 'atom/dr/workContents',
  default: [],
});

export const workContentsWithCascadeState = atom<Array<CascaderOption>>({
  key: 'atom/dr/workContentsWithCascade',
  default: [],
});

export const workDetailsState = atom<Array<WorkContent>>({
  key: 'atom/dr/workDetails',
  default: [],
});

export const departmentsState = atom<Array<Department>>({
  key: 'atom/dr/departments',
  default: [],
});

type FormTypeCMetaData = {
  dummyWorkProperty: WorkProperty;
  breaktimeWorkContent: WorkContent;
};

export const formTypeCMetaDataState = atom<FormTypeCMetaData | null>({
  key: 'atom/dr/formTypeCMetaData',
  default: null,
});

interface DS {
  date: string | null;
}

export const dailyreportState = atom<DS>({
  key: 'atom/dr/dailyreport',
  default: {
    date: null,
  },
});

export const useDRDispatch = () => {
  const setDate = useRecoilCallback(
    ({ set }) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        set(dailyreportState, { date: event.target.value });
      },
  );

  return { setDate };
};
