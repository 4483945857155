import { useLocalStorage } from '@rehooks/local-storage';
import type {
  Work,
  NewWorkWithWorkingForm,
  NewWorkWithWorking,
} from '@/interface';
import { initNewWorkWithWorkingForm } from '@/const';

type UseDailyReportTypeCPersist = {
  userId: string;
  dailyReportDate: string;
  defaultValue: Work[];
};

export const useDailyReportTypeCPersist = ({
  userId,
  dailyReportDate,
  defaultValue,
}: UseDailyReportTypeCPersist) => {
  const DNDD = `dr_newwork_${userId}_${dailyReportDate}`;
  const newWorkState = useLocalStorage<NewWorkWithWorkingForm>(
    DNDD,
    initNewWorkWithWorkingForm,
  );

  const WDNDD = `dr_workingwork_${userId}_${dailyReportDate}`;
  const workingWorkState = useLocalStorage<NewWorkWithWorking | null>(
    WDNDD,
    null,
  );

  const DDD = `dr_works_${userId}_${dailyReportDate}`;
  const worksState = useLocalStorage<Array<Work>>(DDD, defaultValue);

  return { newWorkState, workingWorkState, worksState };
};
