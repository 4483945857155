import React, { FC } from 'react';
import { useRecoilValue, RecoilValueReadOnly } from 'recoil';
import Select from 'react-select';
import { DailyreportsFilterOption } from '@/store/dailyreports-configure';
import { reactSelectStyle } from '@/lib/react-select/reactSelectStyle';

type WorkItemSelectProps = {
  options: Array<{ id: number; name: string }>;
  value: { id: number; name: string } | null;
  onChange: (value: { id: number; name: string }) => void;
  recoilValue: RecoilValueReadOnly<DailyreportsFilterOption>;
  recoilValueKey: keyof DailyreportsFilterOption;
  placeholder?: string;
  noOptionsMessage?: () => string;
};

export const WorkItemSelect: FC<WorkItemSelectProps> = ({
  options,
  value,
  onChange,
  recoilValue,
  recoilValueKey,
  placeholder,
  noOptionsMessage,
}) => {
  const filterOptions = useRecoilValue(recoilValue);

  return (
    <Select
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionLabelValue={(option) => option.id}
      isOptionSelected={(target) => value?.id === target.id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      theme={reactSelectStyle}
      isClearable
      isSearchable
      filterOption={filterOptions[recoilValueKey]}
    />
  );
};
