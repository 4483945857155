import { atom, selector } from 'recoil';
import { createFilter } from 'react-select';
import type { Config } from '@/lib/react-select/types';
import { defaultFilterConfig } from '@/lib/react-select/config';

export type FormKind = {
  workProperty: 'select' | 'cascader';
  workContent: 'select' | 'cascader';
  delimiter: string;
};

export interface DailyReportConfigureState {
  projectSelect: Config;
  workContentSelect: Config;
  workDetailSelect: Config;
}

export type DailyreportsFilterOption = {
  projectFilterOption: ReturnType<typeof createFilter>;
  workContentFilterOption: ReturnType<typeof createFilter>;
  workDetailFilterOption: ReturnType<typeof createFilter>;
};

export const formKind = atom<FormKind>({
  key: 'atom/formKind',
  default: {
    workProperty: 'select',
    workContent: 'select',
    delimiter: ';',
  },
});

export const dailyreportsConfigureState = atom<DailyReportConfigureState>({
  key: 'dailyreportsConfigure',
  default: {
    projectSelect: defaultFilterConfig,
    workContentSelect: defaultFilterConfig,
    workDetailSelect: defaultFilterConfig,
  },
});

export const dailyreportsFilterOption = selector<DailyreportsFilterOption>({
  key: 'selector/dailyreportsConfigure/filterOption',
  get: ({ get }) => {
    const configure = get(dailyreportsConfigureState);

    return {
      projectFilterOption: createFilter(configure.projectSelect),
      workContentFilterOption: createFilter(configure.workContentSelect),
      workDetailFilterOption: createFilter(configure.workDetailSelect),
    };
  },
});

export type EmployeeSelectFilterOption = ReturnType<typeof createFilter>;

export const employeeSelectFilterOptionState = atom<Config>({
  key: 'atom/employeeSelect/FilterOption',
  default: defaultFilterConfig,
});

export const employeeSelectFilterOption = selector<>({
  key: 'selector/employeeSelect/filterOption',
  get: ({ get }) => {
    const configure = get(employeeSelectFilterOptionState);

    return createFilter(configure);
  },
});
