/* eslint-disable */
// File generated by i18n-js on 2024-10-30 23:05:58 +0900

export default {
  "ja": {
    "activemodel": {
      "attributes": {
        "session": {
          "password": "パスワード",
          "unique_id": "企業ID",
          "unique_name": "ユーザID"
        },
        "user_setting/components": {
          "cascader": "階層式",
          "select": "標準"
        },
        "user_setting/search_matches": {
          "any": "部分一致",
          "start": "前方一致"
        }
      }
    },
    "activerecord": {
      "attributes": {
        "arigatona": {
          "checked": "確認",
          "comment": "コメント",
          "daily_report": "activerecord.models.daily_report",
          "user": "activerecord.models.user"
        },
        "category": {
          "client": "activerecord.models.client",
          "description": "説明文",
          "name": "カテゴリ名",
          "users": "activerecord.models.user",
          "work_contents": "activerecord.models.work_content"
        },
        "client": {
          "active": "有効(現在はユーザに影響しない)",
          "arigato_hidden": "ありがとう関連を非表示にする",
          "categories": "activerecord.models.category",
          "departments": "activerecord.models.department",
          "dr_view_type": "日報提出画面の種類",
          "email": "メールアドレス",
          "memo_hidden": "連絡事項関連を非表示にする",
          "name": "クライアント名",
          "notify_enabled": "通知曜日",
          "notify_friday_enabled": "金",
          "notify_monday_enabled": "月",
          "notify_saturday_enabled": "土",
          "notify_sunday_enabled": "日",
          "notify_thursday_enabled": "木",
          "notify_tuesday_enabled": "火",
          "notify_wednesday_enabled": "水",
          "polling_at": "通知時間",
          "type_c_enable": "中央物産向けカスタムフォームの選択肢を表示",
          "type_d_enable": "ストップウォッチ形式+α のカスタムフォーム選択肢を表示",
          "unique_id": "企業ID",
          "users": "activerecord.models.user",
          "users_length": "ユーザ数",
          "users_limit": "最大ユーザ数",
          "work_contents": "activerecord.models.work_content",
          "work_details": "activerecord.models.work_detail",
          "work_properties": "activerecord.models.work_property"
        },
        "client/dr_view_type": {
          "typeA": "標準",
          "typeB": "簡易",
          "typeC": "カスタムフォーム1",
          "typeD": "ストップウォッチ形式 +α"
        },
        "daily_report": {
          "arigatona": "activerecord.models.arigatona",
          "date": "日付",
          "memo": "メモ",
          "user": "activerecord.models.user",
          "works": "activerecord.models.work"
        },
        "department": {
          "client": "activerecord.models.client",
          "name": "部署名",
          "users": "activerecord.models.user"
        },
        "dr_timespan": {
          "client": "activerecord.models.client",
          "end_at": "終了時刻",
          "include_rest": "休憩込み",
          "name": "表示名",
          "sort": "並び順",
          "start_at": "開始時刻"
        },
        "user": {
          "active": "状態",
          "arigatonas": "activerecord.models.arigatona",
          "categories": "activerecord.models.category",
          "client": "activerecord.models.client",
          "daily_reports": "activerecord.models.daily_report",
          "department": "activerecord.models.department",
          "department_id": "activerecord.models.department",
          "name": "ユーザ名",
          "notify_notsubmit_enabled": "日報未提出通知",
          "password": "パスワード",
          "password_confirmation": "パスワード（確認）",
          "password_digest": "パスワードダイジェスト",
          "role": "役割",
          "unique_name": "ユーザID",
          "user_length": "ユーザ数"
        },
        "user/active": {
          "false": "無効",
          "true": "有効"
        },
        "user/role": {
          "client_admin": "管理者",
          "client_general": "一般",
          "client_share_device": "共有デバイス",
          "system_admin": "システム管理者"
        },
        "work": {
          "daily_report": "activerecord.models.daily_report",
          "end_at": "終了時刻",
          "include_rest": "休憩込み",
          "start_at": "開始時刻",
          "work_content": "activerecord.models.work_content",
          "work_detail": "activerecord.models.work_detail",
          "work_detail_amount": "数量",
          "work_property": "activerecord.models.work_property"
        },
        "work_content": {
          "active": "日報に表示",
          "categories": "activerecord.models.category",
          "client": "activerecord.models.client",
          "name": "作業名",
          "name_kana": "作業名カナ",
          "work": "日報"
        },
        "work_detail": {
          "active": "日報に表示",
          "client": "activerecord.models.client",
          "name": "詳細名",
          "name_kana": "詳細名カナ",
          "work": "案件"
        },
        "work_property": {
          "active": "日報に表示",
          "client": "activerecord.models.client",
          "code": "案件コード",
          "name": "案件名",
          "sort": "並び順",
          "work": "日報"
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "バリデーションに失敗しました: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "%{record}が存在しているので削除できません",
            "has_one": "%{record}が存在しているので削除できません"
          }
        },
        "models": {
          "daily_report": {
            "attributes": {
              "date": {
                "format": "%{message}",
                "taken": "提出済みです。日付を確認してください"
              },
              "works": {
                "format": "%{message}",
                "too_short": "作業報告を入力してください"
              }
            }
          }
        }
      },
      "models": {
        "arigatona": "ありがとう",
        "category": "カテゴリ",
        "client": "クライアント",
        "daily_report": "日報",
        "department": "部署",
        "dr_timespan": {
          "one": "期間",
          "other": "期間一覧"
        },
        "user": "ユーザ",
        "work": "作業報告",
        "work_content": "作業内容",
        "work_detail": "作業詳細",
        "work_property": "案件"
      }
    },
    "attributes": {
      "created_at": "登録日時",
      "created_by_id": "登録者",
      "updated_at": "更新日時"
    },
    "categories": {
      "shared": {
        "section": {
          "destroy": "このカテゴリを削除します"
        }
      }
    },
    "client_admin": {
      "categories": {
        "edit": {
          "section_delete": "削除",
          "title": "カテゴリ更新"
        },
        "index": {
          "title": "カテゴリ一覧"
        },
        "new": {
          "title": "カテゴリ登録"
        },
        "users": {
          "edit": {
            "title": "%{name} / ユーザ一覧"
          },
          "index": {
            "title": "%{name} / ユーザ一覧"
          }
        },
        "work_contents": {
          "edit": {
            "title": "%{name} / 作業内容一覧"
          },
          "index": {
            "title": "%{name} / 作業内容一覧"
          }
        }
      },
      "configurations": {
        "dailyreport_configures_step1": {
          "edit": {
            "edit_selected_user": "選択中のユーザを編集"
          }
        },
        "dr_timespans": {
          "edit": {
            "section_delete": "削除",
            "title": "期間編集"
          },
          "index": {
            "sort_desc": "(昇順)",
            "title": "期間一覧"
          },
          "new": {
            "title": "期間登録"
          }
        },
        "edit": {
          "title": "通知設定",
          "view_type_sec": {
            "title": "日報提出画面設定"
          }
        },
        "index": {
          "title": "通知設定"
        }
      },
      "daily_report_confirm": {
        "index": {
          "date": "作業日",
          "title": "日報未提出チェック"
        }
      },
      "daily_report_users": {
        "index": {
          "title": "日報一覧"
        },
        "show": {
          "create": "代理提出",
          "title": "%{name}さんの日報一覧"
        }
      },
      "daily_reports": {
        "form": {
          "section_contact": {
            "title": "4. 連絡事項"
          },
          "section_date": {
            "title": "1. 作業日時"
          },
          "section_dr": {
            "title": "2. 報告内容を編集"
          },
          "section_thx": {
            "title": "3. ありがとうを送る"
          },
          "title": "%{name}さんの日報を修正する"
        },
        "show": {
          "section_contact": {
            "title": "3. 連絡事項"
          },
          "section_dr": {
            "title": "1. 報告された内容"
          },
          "section_thx": {
            "title": "2. 送った\"ありがとう\""
          },
          "title": "%{name}さんの日報"
        }
      },
      "departments": {
        "edit": {
          "section_delete": "削除",
          "title": "部署編集"
        },
        "index": {
          "title": "部署一覧"
        },
        "new": {
          "title": "部署登録"
        }
      },
      "users": {
        "edit": {
          "section_delete": "ユーザ無効化",
          "title": "ユーザ編集"
        },
        "index": {
          "title": "ユーザ一覧"
        },
        "new": {
          "title": "ユーザ登録"
        }
      },
      "work_contents": {
        "edit": {
          "section_delete": "削除",
          "title": "作業編集"
        },
        "index": {
          "title": "作業一覧"
        },
        "new": {
          "title": "作業登録"
        }
      },
      "work_details": {
        "edit": {
          "section_delete": "削除",
          "title": "作業詳細編集"
        },
        "index": {
          "title": "作業詳細一覧"
        },
        "new": {
          "title": "作業詳細登録"
        }
      },
      "work_properties": {
        "edit": {
          "section_delete": "削除",
          "title": "案件編集"
        },
        "index": {
          "title": "案件一覧"
        },
        "new": {
          "title": "案件登録"
        }
      }
    },
    "configurations": {
      "shared": {
        "section": {
          "polling_at": {
            "not_supported": "時間変更は現在サポートしておりません"
          }
        }
      }
    },
    "daily_reports": {
      "daily_reports": {
        "index": {
          "title": "%{name}さんの日報"
        },
        "show": {
          "section_contact": {
            "title": "3. 連絡事項"
          },
          "section_dr": {
            "title": "1. 報告された内容"
          },
          "section_thx": {
            "title": "2. 送った\"ありがとう\""
          }
        }
      },
      "form_edit": {
        "confirm": {
          "update": "更新しますか？"
        },
        "section_contact": {
          "title": "4. 連絡事項"
        },
        "section_date": {
          "title": "1. 作業日時"
        },
        "section_dr": {
          "title": "2. 報告内容を編集"
        },
        "section_thx": {
          "title": "3. ありがとうを送る"
        }
      },
      "shared": {
        "section": {
          "section_thx": {
            "to": "%{name}さんへ"
          }
        }
      },
      "show": {
        "section_contact": {
          "title": "3. 連絡事項"
        },
        "section_dr": {
          "title": "1. 報告された内容"
        },
        "section_thx": {
          "title": "2. 送った\"ありがとう\""
        }
      },
      "users": {
        "index": {
          "no_department": "無所属",
          "section_title": "%{name} / ユーザ一覧",
          "title": "ユーザ一覧"
        },
        "new": {
          "no_department": "無所属",
          "section_title": "%{name} / ユーザ一覧",
          "title": "ユーザ一覧"
        }
      }
    },
    "date": {
      "abbr_day_names": [
        "日",
        "月",
        "火",
        "水",
        "木",
        "金",
        "土"
      ],
      "abbr_month_names": [
        null,
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
      ],
      "day_names": [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日"
      ],
      "formats": {
        "dash": "%Y-%m-%d",
        "default": "%Y/%m/%d",
        "long": "%Y年%m月%d日(%a)",
        "short": "%m/%d"
      },
      "month_names": [
        null,
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "約1時間",
          "other": "約%{count}時間"
        },
        "about_x_months": {
          "one": "約1ヶ月",
          "other": "約%{count}ヶ月"
        },
        "about_x_years": {
          "one": "約1年",
          "other": "約%{count}年"
        },
        "almost_x_years": {
          "one": "1年弱",
          "other": "%{count}年弱"
        },
        "half_a_minute": "30秒前後",
        "less_than_x_minutes": {
          "one": "1分以内",
          "other": "%{count}分未満"
        },
        "less_than_x_seconds": {
          "one": "1秒以内",
          "other": "%{count}秒未満"
        },
        "over_x_years": {
          "one": "1年以上",
          "other": "%{count}年以上"
        },
        "x_days": {
          "one": "1日",
          "other": "%{count}日"
        },
        "x_minutes": {
          "one": "1分",
          "other": "%{count}分"
        },
        "x_months": {
          "one": "1ヶ月",
          "other": "%{count}ヶ月"
        },
        "x_seconds": {
          "one": "1秒",
          "other": "%{count}秒"
        },
        "x_years": {
          "one": "1年",
          "other": "%{count}年"
        }
      },
      "prompts": {
        "day": "日",
        "hour": "時",
        "minute": "分",
        "month": "月",
        "second": "秒",
        "year": "年"
      }
    },
    "departments": {
      "shared": {
        "section": {
          "destroy": "この部署を削除します"
        }
      }
    },
    "dr_timespan": {
      "false": "なし",
      "true": "込"
    },
    "dr_timespans": {
      "shared": {
        "section": {
          "destroy": "この期間を削除します(日報は消えません)"
        }
      }
    },
    "errors": {
      "access_denied": {
        "go back home": "ホームへ戻る",
        "hero": "権限がありません(403)",
        "message": "アクセスするには、適切な権限が必要です。",
        "title": "権限がありません"
      },
      "format": "%{attribute}%{message}",
      "internal_server_error": {
        "go back home": "ホームへ戻る",
        "hero": "深刻な問題が発生しました(500)",
        "message": "再度試して頂いて、問題が解決しないようでしたらご連絡ください。",
        "title": "サーバ内部でエラーが発生しています"
      },
      "messages": {
        "accepted": "を受諾してください",
        "blank": "を入力してください",
        "confirmation": "と%{attribute}の入力が一致しません",
        "empty": "を入力してください",
        "equal_to": "は%{count}にしてください",
        "even": "は偶数にしてください",
        "exclusion": "は予約されています",
        "greater_than": "は%{count}より大きい値にしてください",
        "greater_than_or_equal_to": "は%{count}以上の値にしてください",
        "inclusion": "は一覧にありません",
        "invalid": "は不正な値です",
        "less_than": "は%{count}より小さい値にしてください",
        "less_than_or_equal_to": "は%{count}以下の値にしてください",
        "model_invalid": "バリデーションに失敗しました: %{errors}",
        "not_a_number": "は数値で入力してください",
        "not_an_integer": "は整数で入力してください",
        "odd": "は奇数にしてください",
        "other_than": "は%{count}以外の値にしてください",
        "over_limit": "が上限に達しています",
        "present": "は入力しないでください",
        "required": "を入力してください",
        "start_should_be_before_end": "開始時刻は終了時刻より前にしてください",
        "taken": "はすでに存在します",
        "too_long": "は%{count}文字以内で入力してください",
        "too_short": "は%{count}文字以上で入力してください",
        "work_time_must_not_over_lap": "作業時刻が重複しています",
        "wrong_length": "は%{count}文字で入力してください"
      },
      "not_found": {
        "go back home": "ホームへ戻る",
        "oops": "おっとっと！ 悪いリンクをたどったようです。もし解決が出来ない場合は、教えてください。",
        "page not found": "ページが見つかりません(404)",
        "title": "ページが見つかりません"
      },
      "template": {
        "body": "次の項目を確認してください",
        "header": {
          "one": "%{model}にエラーが発生しました",
          "other": "%{model}に%{count}個のエラーが発生しました"
        }
      }
    },
    "helpers": {
      "done": {
        "create": "登録しました。",
        "destroy": "削除しました。",
        "disable": "無効化しました。",
        "enable": "有効化しました。",
        "handin": "提出しました。",
        "update": "更新しました。"
      },
      "failure": {
        "create": "登録に失敗しました。",
        "destroy": "削除に失敗しました。",
        "disable": "無効化に失敗しました。",
        "employeesnotfound": "従業員を選択してください。",
        "enable": "有効化に失敗しました。",
        "handin": "提出に失敗しました。",
        "update": "更新に失敗しました。",
        "worksnotfound": "作業報告を入力してください。"
      },
      "select": {
        "do_not_change": "変更しない",
        "prompt": "選択してください"
      },
      "state": {
        "dr": {
          "not_submitted": "未提出",
          "submitted": "提出済"
        },
        "hidden": "非表示",
        "none": "なし",
        "notset": "未設定",
        "visible": "表示"
      },
      "submit": {
        "change": "変更",
        "create": "登録",
        "destroy": "削除",
        "disable": "無効化",
        "download": "ダウンロード",
        "edit": "編集",
        "enable": "有効化",
        "handin": "提出",
        "new": "新規作成",
        "submit": "保存",
        "update": "更新"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%n%u",
          "precision": 0,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "円"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "十億",
            "million": "百万",
            "quadrillion": "千兆",
            "thousand": "千",
            "trillion": "兆",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n%u",
          "units": {
            "byte": "バイト",
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "sessions": {
      "destroy": {
        "done": "ログアウトしました"
      }
    },
    "shared": {
      "admin_layout": {
        "user_settings_dr_component": {
          "title": "選択方法の設定"
        },
        "view_type_sec": {
          "title": "日報提出画面設定"
        }
      },
      "section": {
        "confirm": {
          "delete": "本当に削除しますか？"
        }
      }
    },
    "support": {
      "array": {
        "last_word_connector": "、",
        "two_words_connector": "、",
        "words_connector": "、"
      }
    },
    "time": {
      "am": "午前",
      "formats": {
        "dash": "%Y-%m-%d",
        "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
        "long": "%Y/%m/%d %H:%M",
        "short": "%m/%d %H:%M",
        "short_time": "%H:%M"
      },
      "pm": "午後"
    },
    "users": {
      "shared": {
        "form_group": {
          "notify_notsubmit_enable": "有効"
        },
        "section": {
          "confirm": {
            "delete": "本当に無効化しますか？"
          },
          "deactivate": "ユーザを無効化"
        }
      }
    },
    "work_contents": {
      "shared": {
        "section": {
          "destroy": "この作業を削除します"
        }
      }
    },
    "work_details": {
      "shared": {
        "section": {
          "destroy": "この作業詳細を削除します"
        }
      }
    },
    "work_properties": {
      "shared": {
        "section": {
          "destroy": "この案件を削除します"
        }
      }
    }
  }
};
