import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import { DailyReportRepresentationViewTypeAForm } from '@/components/form/DailyReportViewTypeA';
import { initializeState } from '@/store/initializeState/dailyreports';
import type { FormRepresentationProps } from '@/types/pages';

const NewTypeA: FC<FormRepresentationProps> = (props) => {
  const {
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    workDetails,
    dailyReportDate,
    formKind,
    users,
    dailyReportWorks,
  } = props;

  const initState = initializeState({
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    dailyReportDate,
    workDetails,
    departments: [],
    formKind,
  });

  return (
    <RecoilRoot initializeState={initState}>
      <DailyReportRepresentationViewTypeAForm
        users={users}
        dailyReportWorks={dailyReportWorks}
      />
    </RecoilRoot>
  );
};

export default NewTypeA;
