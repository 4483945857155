import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
} from 'recharts';

type ThunksBarGraphProps = {
  title: string;
  values: Array<{
    name: string;
    nthunks: number;
  }>;
};

const ThunksBarGraph: React.FC<ThunksBarGraphProps> = (props) => {
  const {
    title,
    values,
  } = props;

  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <BarChart
        data={values}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="nthunks" fill="#8884d8" name={title} />
        <Brush
          dataKey="name"
          stroke="#8884d8"
          startIndex={0}
          endIndex={5}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ThunksBarGraph;
