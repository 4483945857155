/* eslint-disable */
// Don't edit manually. `rake js:routes` generates this file.
function process(route, params, keys) {
  var query = [];
  for (var param in params) if (Object.prototype.hasOwnProperty.call(params, param)) {
    if (keys.indexOf(param) === -1) {
      if (Array.isArray(params[param])) {
        for (var value of params[param]) {
          query.push(param + "[]=" + encodeURIComponent(value));
        }
      } else {
        query.push(param + "=" + encodeURIComponent(params[param]));
      }
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function healthIndexPath(params) { return process('/health', params, []); }
export function rootPath(params) { return process('/', params, []); }
export function dailyReportsUserDailyReportsPath(params) { return process('/daily_reports/users/' + params.userId + '/daily_reports', params, ['userId']); }
export function newDailyReportsUserDailyReportPath(params) { return process('/daily_reports/users/' + params.userId + '/daily_reports/new', params, ['userId']); }
export function editDailyReportsUserDailyReportPath(params) { return process('/daily_reports/users/' + params.userId + '/daily_reports/' + params.id + '/edit', params, ['userId','id']); }
export function dailyReportsUserDailyReportPath(params) { return process('/daily_reports/users/' + params.userId + '/daily_reports/' + params.id + '', params, ['userId','id']); }
export function dailyReportsUsersPath(params) { return process('/daily_reports/users', params, []); }
export function newDailyReportsUserPath(params) { return process('/daily_reports/users/new', params, []); }
export function dailyReportsPath(params) { return process('/daily_reports', params, []); }
export function newDailyReportPath(params) { return process('/daily_reports/new', params, []); }
export function editDailyReportPath(params) { return process('/daily_reports/' + params.id + '/edit', params, ['id']); }
export function dailyReportPath(params) { return process('/daily_reports/' + params.id + '', params, ['id']); }
export function dailyReportExportPath(params) { return process('/daily_report_export', params, []); }
export function arigatonasPath(params) { return process('/arigatonas', params, []); }
export function arigatonasExportPath(params) { return process('/arigatonas_export', params, []); }
export function signinPath(params) { return process('/signin', params, []); }
export function sessionPath(params) { return process('/signin', params, []); }
export function dashboardIndexPath(params) { return process('/dashboard', params, []); }
export function apiUsersPath(params) { return process('/api/users', params, []); }
export function apiUsersUnsubmitPath(params) { return process('/api/users/unsubmit', params, []); }
export function clientAdminRootPath(params) { return process('/client_admin', params, []); }
export function clientAdminDepartmentsPath(params) { return process('/client_admin/departments', params, []); }
export function newClientAdminDepartmentPath(params) { return process('/client_admin/departments/new', params, []); }
export function editClientAdminDepartmentPath(params) { return process('/client_admin/departments/' + params.id + '/edit', params, ['id']); }
export function clientAdminDepartmentPath(params) { return process('/client_admin/departments/' + params.id + '', params, ['id']); }
export function clientAdminUsersPath(params) { return process('/client_admin/users', params, []); }
export function newClientAdminUserPath(params) { return process('/client_admin/users/new', params, []); }
export function editClientAdminUserPath(params) { return process('/client_admin/users/' + params.id + '/edit', params, ['id']); }
export function clientAdminUserPath(params) { return process('/client_admin/users/' + params.id + '', params, ['id']); }
export function clientAdminUsersExportPath(params) { return process('/client_admin/users_export', params, []); }
export function clientAdminUserActivatePath(params) { return process('/client_admin/users/' + params.userId + '/activate', params, ['userId']); }
export function clientAdminWorkContentsPath(params) { return process('/client_admin/work_contents', params, []); }
export function newClientAdminWorkContentPath(params) { return process('/client_admin/work_contents/new', params, []); }
export function editClientAdminWorkContentPath(params) { return process('/client_admin/work_contents/' + params.id + '/edit', params, ['id']); }
export function clientAdminWorkContentPath(params) { return process('/client_admin/work_contents/' + params.id + '', params, ['id']); }
export function clientAdminWorkPropertiesPath(params) { return process('/client_admin/work_properties', params, []); }
export function newClientAdminWorkPropertyPath(params) { return process('/client_admin/work_properties/new', params, []); }
export function editClientAdminWorkPropertyPath(params) { return process('/client_admin/work_properties/' + params.id + '/edit', params, ['id']); }
export function clientAdminWorkPropertyPath(params) { return process('/client_admin/work_properties/' + params.id + '', params, ['id']); }
export function clientAdminWorkDetailsPath(params) { return process('/client_admin/work_details', params, []); }
export function newClientAdminWorkDetailPath(params) { return process('/client_admin/work_details/new', params, []); }
export function editClientAdminWorkDetailPath(params) { return process('/client_admin/work_details/' + params.id + '/edit', params, ['id']); }
export function clientAdminWorkDetailPath(params) { return process('/client_admin/work_details/' + params.id + '', params, ['id']); }
export function editClientAdminDailyReportPath(params) { return process('/client_admin/daily_reports/' + params.id + '/edit', params, ['id']); }
export function clientAdminDailyReportPath(params) { return process('/client_admin/daily_reports/' + params.id + '', params, ['id']); }
export function newClientAdminDailyReportUserPath(params) { return process('/client_admin/daily_report_users/' + params.id + '/new', params, ['id']); }
export function createClientAdminDailyReportUserPath(params) { return process('/client_admin/daily_report_users/' + params.id + '', params, ['id']); }
export function clientAdminDailyReportUsersPath(params) { return process('/client_admin/daily_report_users', params, []); }
export function clientAdminDailyReportUserPath(params) { return process('/client_admin/daily_report_users/' + params.id + '', params, ['id']); }
export function clientAdminDailyReportsRepresentationIndexPath(params) { return process('/client_admin/daily_reports_representation', params, []); }
export function newClientAdminDailyReportsRepresentationPath(params) { return process('/client_admin/daily_reports_representation/new', params, []); }
export function clientAdminDailyReportConfirmIndexPath(params) { return process('/client_admin/daily_report_confirm', params, []); }
export function clientAdminDashboardIndexPath(params) { return process('/client_admin/dashboard', params, []); }
export function clientAdminConfigurationsDrTimespansPath(params) { return process('/client_admin/configurations/dr_timespans', params, []); }
export function newClientAdminConfigurationsDrTimespanPath(params) { return process('/client_admin/configurations/dr_timespans/new', params, []); }
export function editClientAdminConfigurationsDrTimespanPath(params) { return process('/client_admin/configurations/dr_timespans/' + params.id + '/edit', params, ['id']); }
export function clientAdminConfigurationsDrTimespanPath(params) { return process('/client_admin/configurations/dr_timespans/' + params.id + '', params, ['id']); }
export function editClientAdminConfigurationsDailyreportConfiguresStep1Path(params) { return process('/client_admin/configurations/dailyreport_configures_step1/' + params.id + '/edit', params, ['id']); }
export function clientAdminConfigurationsDailyreportConfiguresStep1Path(params) { return process('/client_admin/configurations/dailyreport_configures_step1/' + params.id + '', params, ['id']); }
export function clientAdminConfigurationsDailyreportConfiguresStep2Path(params) { return process('/client_admin/configurations/dailyreport_configures_step2/' + params.id + '', params, ['id']); }
export function clientAdminConfigurationsPath(params) { return process('/client_admin/configurations', params, []); }
export function editClientAdminConfigurationPath(params) { return process('/client_admin/configurations/' + params.id + '/edit', params, ['id']); }
export function clientAdminConfigurationPath(params) { return process('/client_admin/configurations/' + params.id + '', params, ['id']); }
export function clientAdminCategoryCategoriesWorkContentsPath(params) { return process('/client_admin/categories/' + params.categoryId + '/categories/work_contents', params, ['categoryId']); }
export function clientAdminCategoryCategoriesWorkContentsEditPath(params) { return process('/client_admin/categories/' + params.categoryId + '/categories/work_contents/edit', params, ['categoryId']); }
export function clientAdminCategoryCategoriesUsersPath(params) { return process('/client_admin/categories/' + params.categoryId + '/categories/users', params, ['categoryId']); }
export function clientAdminCategoryCategoriesUsersEditPath(params) { return process('/client_admin/categories/' + params.categoryId + '/categories/users/edit', params, ['categoryId']); }
export function clientAdminCategoriesPath(params) { return process('/client_admin/categories', params, []); }
export function newClientAdminCategoryPath(params) { return process('/client_admin/categories/new', params, []); }
export function editClientAdminCategoryPath(params) { return process('/client_admin/categories/' + params.id + '/edit', params, ['id']); }
export function clientAdminCategoryPath(params) { return process('/client_admin/categories/' + params.id + '', params, ['id']); }
export function editClientAdminConfigurationsClientWorkContentsPath(params) { return process('/client_admin/configurations/clients/' + params.clientId + '/work_contents/edit', params, ['clientId']); }
export function clientAdminConfigurationsClientWorkContentsPath(params) { return process('/client_admin/configurations/clients/' + params.clientId + '/work_contents', params, ['clientId']); }
export function clientAdminConfigurationsClientsPath(params) { return process('/client_admin/configurations/clients', params, []); }
export function newClientAdminConfigurationsClientPath(params) { return process('/client_admin/configurations/clients/new', params, []); }
export function editClientAdminConfigurationsClientPath(params) { return process('/client_admin/configurations/clients/' + params.id + '/edit', params, ['id']); }
export function clientAdminConfigurationsClientPath(params) { return process('/client_admin/configurations/clients/' + params.id + '', params, ['id']); }
export function systemAdminRootPath(params) { return process('/system_admin', params, []); }
export function systemAdminClientsPath(params) { return process('/system_admin/clients', params, []); }
export function newSystemAdminClientPath(params) { return process('/system_admin/clients/new', params, []); }
export function editSystemAdminClientPath(params) { return process('/system_admin/clients/' + params.id + '/edit', params, ['id']); }
export function systemAdminClientPath(params) { return process('/system_admin/clients/' + params.id + '', params, ['id']); }
export function lpPath(params) { return process('/lp', params, []); }
export function railsPostmarkInboundEmailsPath(params) { return process('/rails/action_mailbox/postmark/inbound_emails', params, []); }
export function railsRelayInboundEmailsPath(params) { return process('/rails/action_mailbox/relay/inbound_emails', params, []); }
export function railsSendgridInboundEmailsPath(params) { return process('/rails/action_mailbox/sendgrid/inbound_emails', params, []); }
export function railsMandrillInboundHealthCheckPath(params) { return process('/rails/action_mailbox/mandrill/inbound_emails', params, []); }
export function railsMandrillInboundEmailsPath(params) { return process('/rails/action_mailbox/mandrill/inbound_emails', params, []); }
export function railsMailgunInboundEmailsPath(params) { return process('/rails/action_mailbox/mailgun/inbound_emails/mime', params, []); }
export function railsConductorInboundEmailsPath(params) { return process('/rails/conductor/action_mailbox/inbound_emails', params, []); }
export function newRailsConductorInboundEmailPath(params) { return process('/rails/conductor/action_mailbox/inbound_emails/new', params, []); }
export function editRailsConductorInboundEmailPath(params) { return process('/rails/conductor/action_mailbox/inbound_emails/' + params.id + '/edit', params, ['id']); }
export function railsConductorInboundEmailPath(params) { return process('/rails/conductor/action_mailbox/inbound_emails/' + params.id + '', params, ['id']); }
export function railsConductorInboundEmailReroutePath(params) { return process('/rails/conductor/action_mailbox/' + params.inboundEmailId + '/reroute', params, ['inboundEmailId']); }
export function railsServiceBlobPath(params) { return process('/rails/active_storage/blobs/' + params.signedId + '/*filename', params, ['signedId']); }
export function railsBlobRepresentationPath(params) { return process('/rails/active_storage/representations/' + params.signedBlobId + '/' + params.variationKey + '/*filename', params, ['signedBlobId','variationKey']); }
export function railsDiskServicePath(params) { return process('/rails/active_storage/disk/' + params.encodedKey + '/*filename', params, ['encodedKey']); }
export function updateRailsDiskServicePath(params) { return process('/rails/active_storage/disk/' + params.encodedToken + '', params, ['encodedToken']); }
export function railsDirectUploadsPath(params) { return process('/rails/active_storage/direct_uploads', params, []); }
