import React, { FC, useReducer, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  workPropertiesState,
  workPropertiesWithCascadeState,
  workContentsState,
  workContentsWithCascadeState,
} from '@/store/dailyreports/atom';
import { Work, TimeAlias } from '@/interface';
import DailyReportTimespanWorksForm from './DailyReportTimespanWorksForm';
import { reducer } from './state';
import { getWorks4Timespan } from './operations';

export interface IDailyReportWorksFormDraftContainer {
  dailyReportDate: string;
  dailyReportWorks: Work[];
  timespanInfo: Array<TimeAlias>;
}

const DailyReportTimespanWorksFormDraftContainer: FC<
  IDailyReportWorksFormDraftContainer
> = ({ dailyReportWorks, timespanInfo }) => {
  const workProperties = useRecoilValue(workPropertiesState);
  const workPropertyOptions = useRecoilValue(workPropertiesWithCascadeState);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);

  const defaultValue = useMemo(
    () => getWorks4Timespan(dailyReportWorks, timespanInfo),
    [timespanInfo, dailyReportWorks],
  );

    const [state, dispatch] = useReducer(reducer, {
      works: defaultValue,
      showModal: false,
      overtimeWork: { startAt: '17:00' },
    });
    const { works } = state;
    const setWorks = (v: Work[]) => {
      dispatch({ type: 'SET_WORKS', payload: { works: v } });
    };

    const handleChangeNewWork = (idx: number | null, work: Work) => {
      if (idx === null) {
        setWorks([...works, work]);
      } else {
        works[idx] = work;
        setWorks([...works]);
      }
    };

    const handleOvertimeDelete = (index: number) => {
      const newWorks = works.filter((_, idx) => idx !== index);
      setWorks(newWorks);
    };

    return (
      <DailyReportTimespanWorksForm
        workContents={workContents}
        workContentOptions={workContentOptions}
        workProperties={workProperties}
        workPropertyOptions={workPropertyOptions}
        handleChangeNewWork={handleChangeNewWork}
        handleDelete={handleOvertimeDelete}
        timespanInfo={timespanInfo}
        state={state}
        dispatch={dispatch}
      />
    );
  };

export default DailyReportTimespanWorksFormDraftContainer;
