import React, { FC } from 'react';
import type { Work } from '@/interface';
import { WorkEntity } from '@/components/WorkEntity';

type WorkEntityListWithHiddenProps = {
  works: Array<Work>;
};

export const WorkEntityListWithHidden: FC<WorkEntityListWithHiddenProps> = ({
  works,
}) => (
  <div className="hidden">
    {works.map((work, index) => (
      <WorkEntity
        key={index}
        work={work}
        handleDelete={() => undefined}
        index={index}
      />
    ))}
  </div>
);
