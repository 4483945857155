import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd-mobile';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { initializeState } from '@/store/initializeState/dailyreports';
import type { FormProps } from '@/types/pages';

import { DailyReportViewTypeAForm } from '@/components/form/DailyReportViewTypeA';

const Form: FC<FormProps> = (props) => {
  const {
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    workDetails,
    departments,
    formKind,
    dailyReportDate,
    dailyReportPersisted,
    dailyReportWorks,
    dailyReportArigatona,
    thunksComment,
    memo,
  } = props;

  const initState = initializeState({
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    workDetails,
    departments,
    formKind,
    dailyReportDate,
  });

  return (
    <ConfigProvider locale={jaJp}>
      <RecoilRoot initializeState={initState}>
        <DailyReportViewTypeAForm
          readOnly={dailyReportPersisted}
          dailyReportWorks={dailyReportWorks}
          dailyReportArigatona={dailyReportArigatona}
          thunksComment={thunksComment}
          memo={memo}
        />
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default Form;
