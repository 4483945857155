import React, { FC } from 'react';
import { List, Empty, Space } from 'antd-mobile';
import { EditSFill, MinusCircleOutline } from 'antd-mobile-icons';
import type { Work } from '@/interface';

type WorkContentListProps = {
  works: Array<Work>;
  onClickEdit: (index: number, work: Work) => void;
  onClickDelete: (index: number, work: Work) => void;
};

export const WorkContentList: FC<WorkContentListProps> = ({
  works,
  onClickEdit,
  onClickDelete,
}) => (
  <List header="今日の作業" style={{ '--padding-left': '0' }}>
    {works.length === 0 && (
      <List.Item>
        <Empty style={{ padding: '64px 0' }} imageStyle={{ width: 128 }} />
      </List.Item>
    )}
    {works.map((work, idx) => (
      <List.Item
        className="prefix-items-center extra-items-center"
        key={`${idx}_${work.startAt}_${work.endAt}`}
        prefix={
          <button type="button" className="text-red-600">
            <MinusCircleOutline
              fontSize={24}
              onClick={() => onClickDelete(idx, work)}
            />
          </button>
        }
        extra={
          <button type="button" className="text-gray-600">
            <EditSFill fontSize={24} onClick={() => onClickEdit(idx, work)} />
          </button>
        }
      >
        <Space align="center" className="adm-space-item-flex-unset">
          <div className="text-right">
            <div className="text-sm">{work.startAt}〜</div>
            <div className="text-sm">{work.endAt}</div>
          </div>
          <div className="text-sm">{work.workContent.name}</div>
        </Space>
      </List.Item>
    ))}
  </List>
);
