import React, { useState } from 'react';
import {
  PieChart, Pie, Cell,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import chroma from 'chroma-js';

const RADIAN = Math.PI / 180;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape: React.FC<any> = (props) => {
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {payload.name}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${value} h `}
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

interface PieGraphProps {
  graphData: Array<{ name: string, value: number }>;
}

const PieGraph: React.FC<PieGraphProps> = (props) => {
  const {
    graphData,
  } = props;

  const [activePie, setActivePie] = useState<number>(0);

  const colors = chroma.scale('Spectral').mode('lch').colors(graphData.length);

  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <PieChart>
        <Pie
          dataKey="value"
          data={graphData}
          labelLine={false}
          activeIndex={activePie}
          activeShape={renderActiveShape}
          onMouseEnter={(x, i) => setActivePie(i)}
        >
          {
            graphData.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} />)
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieGraph;
