import type { Work, NewWorkWithWorking } from '@/interface';

export const isWork = (v: unknown): v is Work => {
  if (typeof v !== 'object' || v === null) {
    return false;
  }

  const candidate = v as Partial<Work>;
  return (
    typeof candidate.startAt === 'string' && typeof candidate.endAt === 'string'
  );
};

export const isNewWorkWithWorking = (v: unknown): v is NewWorkWithWorking => {
  const candidate = v as Partial<NewWorkWithWorking>;

  return candidate.object === 'newWorkWithWorking';
};
