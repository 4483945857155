import React, { FC, useMemo } from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { ConfigProvider } from 'antd-mobile';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import type { Work } from '@/interface';
import { useDailyReportTypeC } from '@/hooks';
import { DailyReportViewTypeCForm } from '@/components/form/DailyReportViewTypeC';
import { initializeFormTypeCState } from '@/store/initializeState/dailyreports';
import type { FormTypeCProps } from '@/types/pages';
import { dailyreportState } from '@/store/dailyreports/atom';

const InnerEdit = ({ works }: { works: Array<Work> }) => {
  const { date } = useRecoilValue(dailyreportState);

  const { worksState, workingWorkState, newWorkState } = useDailyReportTypeC({
    defaultValue: works,
  });

  return (
    <DailyReportViewTypeCForm
      date={date}
      setDate={() => undefined}
      worksState={worksState}
      workingWorkState={workingWorkState}
      newWorkState={newWorkState}
      submitText="更新"
    />
  );
};

const EditTypeC: FC<FormTypeCProps> = (props) => {
  const {
    dailyReportDate,
    dailyReportWorks,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
  } = props;

  const initState = initializeFormTypeCState({
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
  });

  const works = useMemo(
    () =>
      dailyReportWorks.map((w) => {
        if (w.workContent.id === breaktimeWorkContent.id) {
          return { ...w, type: 'breaktime' };
        }
        return w;
      }),
    [dailyReportWorks],
  );

  return (
    <ConfigProvider locale={jaJp}>
      <RecoilRoot initializeState={initState}>
        <InnerEdit works={works} />
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default EditTypeC;
