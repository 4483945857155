import {
  State as S1,
  Action,
  reducer as baseReducer,
} from '@/components/DailyReportViewTypeB/state';
import { User } from '@/interface';

export interface State extends S1 {
  selectedUsers: Array<User>;
}

export const defaultState: State = {
  showModal: false,
  overtimeWork: null,
  works: [],
  selectedUsers: [],
};

export const reducer = (state: State, action: Action): State => {
  const newState = baseReducer(state, action);

  switch (action.type) {
    case 'SET_EMPLOYEES':
      return { ...newState, selectedUsers: action.payload };
    default:
      break;
  }

  return { ...state, ...newState };
};
