import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import DailyReportTimespanContainer from '@/components/DailyReportViewTypeB/DailyReportTimespanContainer';
import { initializeState } from '@/store/initializeState/dailyreports';
import type { FormTimespanProps } from '@/types/pages';

const FormTimespan: FC<FormTimespanProps> = (props) => {
  const {
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    workDetails,
    departments,
    formKind,
    dailyReportDate,
    ...other
  } = props;

  const initState = initializeState({
    projectMatchFrom,
    workContentMatchFrom,
    workDetailMatchFrom,
    workProperties,
    workPropertiesWithCascade,
    workContents,
    workContentsWithCascade,
    workDetails,
    departments,
    formKind,
    dailyReportDate,
  });

  return (
    <RecoilRoot initializeState={initState}>
      <DailyReportTimespanContainer {...other} />
    </RecoilRoot>
  );
};

export default FormTimespan;
