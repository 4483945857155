import { useState } from 'react';
import type {
  Work,
  NewWorkWithWorkingForm,
  NewWorkWithWorking,
  DispatchReturn,
} from '@/interface';
import { initNewWorkWithWorkingForm } from '@/const';

type UseDailyReportTypeC = {
  defaultValue: Array<Work>;
};

export const useDailyReportTypeC = ({ defaultValue }: UseDailyReportTypeC) => {
  const [newWork, setNewWork] = useState<NewWorkWithWorkingForm>(
    initNewWorkWithWorkingForm,
  );

  const [workingWork, setWorkingWork] = useState<NewWorkWithWorking | null>(
    null,
  );

  const [works, setWorks] = useState<Array<Work>>(defaultValue ?? []);

  return {
    newWorkState: [
      newWork,
      (v: NewWorkWithWorkingForm) => setNewWork(v),
      () => undefined,
    ] as DispatchReturn<NewWorkWithWorkingForm>,
    workingWorkState: [
      workingWork,
      (v: NewWorkWithWorking | null) => setWorkingWork(v),
      () => undefined,
    ] as DispatchReturn<NewWorkWithWorking | null>,
    worksState: [
      works,
      (v: Array<Work>) => setWorks(v),
      () => undefined,
    ] as DispatchReturn<Array<Work>>,
  };
};
