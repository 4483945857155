import React, { FC } from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { ConfigProvider } from 'antd-mobile';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { useDailyReport } from '@/hooks';
import { User } from '@/interface';
import type { FormTypeCProps } from '@/types/pages';
import { initializeFormTypeCState } from '@/store/initializeState/dailyreports';
import { dailyreportState, useDRDispatch } from '@/store/dailyreports/atom';
import { DailyReportRepresentationViewTypeCForm } from '@/components/form/DailyReportViewTypeC/DailyReportRepresentationViewTypeCForm';

const InnerNew = ({ dailyReportWorks, users }) => {
  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();
  const { worksState, newWorkState } = useDailyReport({
    defaultValue: dailyReportWorks,
  });

  return (
    <DailyReportRepresentationViewTypeCForm
      date={date}
      setDate={setDate}
      worksState={worksState}
      newWorkState={newWorkState}
      users={users}
    />
  );
};

const NewTypeC: FC<FormTypeCProps & { users: Array<User> }> = ({
  dailyReportDate,
  workContents,
  workContentsWithCascade,
  formKind,
  dummyWorkProperty,
  breaktimeWorkContent,
  dailyReportWorks,
  users,
}) => {
  const initState = initializeFormTypeCState({
    dailyReportDate,
    workContents,
    workContentsWithCascade,
    formKind,
    dummyWorkProperty,
    breaktimeWorkContent,
  });

  return (
    <ConfigProvider locale={jaJp}>
      <RecoilRoot initializeState={initState}>
        <InnerNew dailyReportWorks={dailyReportWorks} users={users} />
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default NewTypeC;
