import { useState } from 'react';
import type { Work, DispatchReturn } from '@/interface';
import { initNewWork } from '@/const';

type UseDailyReport = {
  defaultValue: Array<Work>;
};

export const useDailyReport = ({ defaultValue }: UseDailyReport) => {
  const [newWork, setNewWork] = useState<Partial<Work>>(initNewWork);
  const [works, setWorks] = useState<Array<Work>>(defaultValue ?? []);

  return {
    newWorkState: [
      newWork,
      (v: Work) => setNewWork(v),
      () => undefined,
    ] as DispatchReturn<Work>,
    worksState: [
      works,
      (v: Array<Work>) => setWorks(v),
      () => undefined,
    ] as DispatchReturn<Array<Work>>,
  };
};
