import React, { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useReducerPersisted from 'use-reducer-persisted';
import Select, { createFilter } from 'react-select';
import { SectionLayout } from '@/components/SectionLayout';
import { FormGroup } from '@/components/FormGroup';
import DailyReportTimespanWorksForm from '@/components/DailyReportViewTypeB/DailyReportTimespanWorksForm';
import { getWorks4Timespan } from '@/components/DailyReportViewTypeB/operations';
import { Work, TimeAlias, User } from '@/interface';
import { reactSelectStyle } from '@/lib/react-select/reactSelectStyle';
import { useUsersWithUnsubmit } from '@/hooks/api';
import {
  workPropertiesState,
  workPropertiesWithCascadeState,
  workContentsState,
  workContentsWithCascadeState,
  dailyreportState,
  useDRDispatch,
} from '@/store/dailyreports/atom';
import { defaultState, reducer } from './state';

interface Config {
  ignoreCase?: boolean;
  ignoreAccents?: boolean;
  trim?: boolean;
  matchFrom?: 'any' | 'start';
}

const filterConfig: Config = {
  ignoreCase: true,
  ignoreAccents: false,
  trim: false,
  matchFrom: 'start',
};

export interface DailyReportsRepresentationContainerProps {
  dailyReportWorks: Array<Work>;
  timespanInfo: Array<TimeAlias>;
  users: Array<User>;
}

const DailyReportsRepresentationContainer: FC<
  DailyReportsRepresentationContainerProps
> = ({ dailyReportWorks, timespanInfo, users }) => {
  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();
  const workProperties = useRecoilValue(workPropertiesState);
  const workPropertyOptions = useRecoilValue(workPropertiesWithCascadeState);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);

  const { users: fetchUsers, isLoading } = useUsersWithUnsubmit(
    { target: date },
    users,
  );

  const options = useMemo(
    () => fetchUsers.map((user) => ({ label: user.name, value: user.id })),
    [fetchUsers],
  );

  const defaultValue = useMemo(
    () => getWorks4Timespan(dailyReportWorks, timespanInfo),
    [timespanInfo, dailyReportWorks],
  );

  const [state, dispatch] = useReducerPersisted(
    'representation',
    reducer,
    {
      ...defaultState,
      works: defaultValue,
    },
    'session',
  );

  const { works, selectedUsers } = state;

  const onDailyReportDate = (ev) => {
    setDate(ev);
    dispatch({ type: 'SET_EMPLOYEES', payload: [] });
  };

  const setWorks = (v: Work[]) => {
    dispatch({ type: 'SET_WORKS', payload: { works: v } });
  };

  const handleChangeNewWork = (idx: number | null, work: Work) => {
    if (idx === null) {
      setWorks([...works, work]);
    } else {
      works[idx] = work;
      setWorks([...works]);
    }
  };

  const handleOvertimeDelete = (index: number) => {
    const newWorks = works.filter((_, idx) => idx !== index);
    setWorks(newWorks);
  };

  const onChangePerson = (value: Array<User>) => {
    dispatch({ type: 'SET_EMPLOYEES', payload: value });
  };

  return (
    <>
      <SectionLayout title="1. 作業日時">
        <FormGroup label="日付" required>
          <input
            type="date"
            name="daily_report[date]"
            id="daily_report_date"
            className="form-control"
            required
            value={date}
            onChange={onDailyReportDate}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="2. 従業員を選択">
        <FormGroup label="従業員" required>
          <Select
            options={options}
            value={selectedUsers}
            onChange={onChangePerson}
            placeholder="従業員を選択してください..."
            noOptionsMessage={() => '従業員が見つかりません'}
            theme={reactSelectStyle}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            isSearchable
            filterOption={createFilter(filterConfig)}
            isLoading={isLoading}
          />
          {selectedUsers.map((user) => (
            <input
              key={user.value}
              type="hidden"
              name="employees[]"
              value={user.value}
            />
          ))}
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="3. 報告内容を編集">
        <DailyReportTimespanWorksForm
          workContents={workContents}
          workContentOptions={workContentOptions}
          workProperties={workProperties}
          workPropertyOptions={workPropertyOptions}
          handleChangeNewWork={handleChangeNewWork}
          handleDelete={handleOvertimeDelete}
          timespanInfo={timespanInfo}
          state={state}
          dispatch={dispatch}
        />
      </SectionLayout>
    </>
  );
};

export default DailyReportsRepresentationContainer;
