// This file is automatically compiled by Webpack,
// along with any other files
// present in this directory.
// You're encouraged to place your actual application logic in
// a relevant structure within app/javascript
// and only use these pack files to reference
// that code so it'll be compiled.
/* eslint-disable */

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('@fortawesome/fontawesome-free/css/all.css');
require('../stylesheets/application.scss');
require('../../assets/javascripts/serviceworker-companion');

// Uncomment to copy all static images
// under../ images to the output folder and reference
// them with the image_pack_tag helper
// in views(e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true);
const imagePath = (name) => images(name, true); // eslint-disable-line no-unused-vars
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

// eslint-disable-next-line
ReactRailsUJS.useContext(componentRequireContext);

/* eslint-enable */
