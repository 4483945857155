import { useCallback, ChangeEvent } from 'react';
import { useRecoilValue } from 'recoil';
import { Toast } from 'antd-mobile';
import type { WorkContent, Work, DispatchReturn } from '@/interface';
import { initNewWork } from '@/const';
import { formTypeCMetaDataState } from '@/store/dailyreports/atom';
import { isValidWork } from '@/utils/isValidWork';
import { isTimePeriodValid } from '@/validators';

type UseDailyReportRepresentTypeCControl = {
  worksState: DispatchReturn<Array<Work>>;
  newWorkState: DispatchReturn<Work>;
};

export const useDailyReportRepresentTypeCControl = ({
  worksState,
  newWorkState,
}: UseDailyReportRepresentTypeCControl) => {
  const { dummyWorkProperty, breaktimeWorkContent } = useRecoilValue(
    formTypeCMetaDataState,
  );
  const [newWork, setNewWork] = newWorkState;
  const [works, setWorks] = worksState;

  const handleChange = useCallback(
    (value: { id: number; name: string } | null) => {
      setNewWork({
        ...newWork,
        workProperty: dummyWorkProperty,
        workContent: value as WorkContent,
      });
    },
    [setNewWork, newWork],
  );

  const handleChangeStartAt = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewWork({
        ...newWork,
        startAt: event.target.value,
      });
    },
    [setNewWork, newWork],
  );

  const handleChangeEndAt = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewWork({
        ...newWork,
        endAt: event.target.value,
      });
    },
    [setNewWork, newWork],
  );

  const handleAdd = useCallback(() => {
    if (!isValidWork(newWork)) {
      Toast.show({
        icon: 'fail',
        content: '作業内容を入力してください',
        position: 'top',
      });

      return;
    }

    if (!isTimePeriodValid(newWork)) {
      Toast.show({
        icon: 'fail',
        content: '終了時刻を開始時刻より後にしてください',
        position: 'top',
      });

      return;
    }

    const updateWorks = [
      ...works,
      {
        ...newWork,
      },
    ];
    setWorks(updateWorks);
    setNewWork({ ...initNewWork } as Work);
  }, [setWorks, setNewWork, newWork, works, dummyWorkProperty]);

  const handleDelete = useCallback(
    (index: number) => {
      setWorks(works.filter((_, i) => i !== index));
    },
    [setWorks, works],
  );

  const handleClickBreaktime = useCallback(() => {
    setNewWork({
      ...newWork,
      workProperty: dummyWorkProperty,
      workContent: breaktimeWorkContent,
    });
  }, [setNewWork, newWork, dummyWorkProperty, breaktimeWorkContent]);

  return {
    newWork,
    works,
    handleChange,
    handleChangeStartAt,
    handleChangeEndAt,
    handleAdd,
    handleDelete,
    handleClickBreaktime,
  };
};
