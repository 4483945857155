import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import Select from 'react-select';
import validate from 'validate.js';
import {
  dailyreportsFilterOption,
  formKind,
} from '@/store/dailyreports-configure';
import { reactSelectStyle } from '@/lib/react-select/reactSelectStyle';
import { WorkProperty, WorkContent, Work } from '@/interface';
import {
  WorkPropertySelect,
  WorkPropertyCascader,
  WorkItemSelect,
  WorkItemCascader,
} from '@/components/form/dailyReport/workProperty';
import { FormGroup, FormGroupToggle } from './FormGroup';

const constraints = {
  format: {
    pattern: /^[0-9]*$/,
  },
};

const VALID = undefined;

interface IWorkForm {
  workContents: WorkContent[];
  workContentOptions: Array<CascaderOption>;
  workProperties: WorkProperty[];
  workPropertyOptions: Array<CascaderOption>;
  workDetails: WorkContent[];
  newWork: Work;
  handleChangeNewWork: (work: Work) => void;
}

export const WorkForm: FC<IWorkForm> = ({
  workContents,
  workContentOptions,
  workProperties,
  workPropertyOptions,
  workDetails,
  newWork,
  handleChangeNewWork,
}) => {
  const formKindConfigure = useRecoilValue(formKind);
  const { workDetailFilterOption } = useRecoilValue(
    dailyreportsFilterOption,
  );

  return (
    <>
      <FormGroup label="案件" required>
        {formKindConfigure.workProperty === 'select' && (
          <WorkPropertySelect
            workProperties={workProperties}
            newWork={newWork}
            onChange={handleChangeNewWork}
          />
        )}
        {formKindConfigure.workProperty === 'cascader' && (
          <WorkPropertyCascader
            delimiter={formKindConfigure.delimiter}
            options={workPropertyOptions}
            workProperties={workProperties}
            newWork={newWork}
            onChange={handleChangeNewWork}
          />
        )}
      </FormGroup>
      <FormGroup label="作業" required>
        {formKindConfigure.workContent === 'select' && (
          <WorkItemSelect
            options={workContents}
            value={newWork?.workContent}
            recoilValue={dailyreportsFilterOption}
            recoilValueKey="workContentFilterOption"
            placeholder="作業を選択してください..."
            noOptionsMessage={() => '作業が見つかりません'}
            onChange={(target) => {
              const workContent =
                target === null ? null : { id: target.id, name: target.name };
              handleChangeNewWork({
                ...newWork,
                workContent,
              });
            }}
          />
        )}
        {formKindConfigure.workContent === 'cascader' && (
          <WorkItemCascader
            delimiter={formKindConfigure.delimiter}
            options={workContentOptions}
            items={workContents}
            value={newWork?.workContent}
            title="作業"
            placeholder="作業を選択してください..."
            valueLabelOr="作業を選択できていません"
            onChange={(value) => {
              handleChangeNewWork({
                ...newWork,
                workContent: value,
              });
            }}
          />
        )}
      </FormGroup>
      <FormGroup label="詳細">
        <Select
          options={workDetails}
          getOptionLabel={(option) => option.name}
          getOptionLabelValue={(option) => option.id}
          isOptionSelected={(target) => newWork?.workDetail?.id === target.id}
          value={newWork?.workDetail}
          onChange={(target) =>
            handleChangeNewWork({ ...newWork, workDetail: target })
          }
          placeholder="選択してください..."
          noOptionsMessage={() => '選択肢がありません'}
          theme={reactSelectStyle}
          isClearable
          isSearchable
          filterOption={workDetailFilterOption}
        />
      </FormGroup>
      <FormGroup label="数量">
        <input
          inputMode="numeric"
          type="text"
          className="form-control"
          value={newWork?.amount || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const ret = validate.single(event.target.value, constraints);
            if (ret === VALID) {
              handleChangeNewWork({ ...newWork, amount: event.target.value });
            }
          }}
        />
      </FormGroup>
      <FormGroup label="開始時刻" required>
        <input
          type="time"
          className="form-control"
          value={newWork?.startAt}
          onChange={(ev) =>
            handleChangeNewWork({ ...newWork, startAt: ev.target.value })
          }
        />
      </FormGroup>
      <FormGroup label="終了時刻" required>
        <input
          type="time"
          className="form-control"
          value={newWork?.endAt}
          onChange={(ev) =>
            handleChangeNewWork({ ...newWork, endAt: ev.target.value })
          }
        />
      </FormGroup>

      <FormGroupToggle label="昼休憩をまたぐ">
        <input
          type="checkbox"
          className="form-control"
          checked={newWork?.includeRest}
          onChange={(ev) =>
            handleChangeNewWork({ ...newWork, includeRest: ev.target.checked })
          }
        />
      </FormGroupToggle>
    </>
  );
};
