import React, { FC, useMemo, useState } from 'react';
import type {
  CascaderValue,
  CascaderOption,
} from 'antd-mobile/es/components/cascader';
import { Cascader } from '@/components/form/dailyReport/Cascader';
import { WorkProperty, Work } from '@/interface';
import { findCascaderValue, makeCascaderDefaultValue } from '@/utils/cascader';

type WorkPropertyCascaderProps = {
  delimiter: string;
  options: Array<CascaderOption>;
  workProperties: Array<WorkProperty>;
  newWork: { workProperty: WorkProperty | null };
  onChange: (work: Work) => void;
};

export const WorkPropertyCascader: FC<WorkPropertyCascaderProps> = ({
  delimiter,
  options,
  workProperties,
  newWork,
  onChange,
}) => {
  const defaultValue = makeCascaderDefaultValue(
    newWork?.workProperty,
    delimiter,
  );

  const [workProperty, setWorkProperty] =
    useState<Array<CascaderValue>>(defaultValue);

  const valueLabel = useMemo(
    () =>
      findCascaderValue(workProperties, workProperty)
        .map((wp) => wp.name)
        .unwrapOr(null),
    [workProperties, workProperty],
  );

  return (
    <Cascader
      options={options}
      value={workProperty}
      valueLabel={valueLabel}
      valueLabelOr="案件を選択できていません"
      title="案件"
      placeholder="案件を選択してください..."
      onConfirm={(value) => {
        setWorkProperty(value);
        const ret = findCascaderValue(workProperties, value).unwrapOr(null);
        onChange({
          ...newWork,
          workProperty: ret,
        });
      }}
    />
  );
};
