import React, { FC } from 'react';

// バックエンドが必要なため、Dummy用
export const ThunksWithHidden: FC = () => (
  <>
    <input
      className="hidden"
      name="arigatona[user_id]"
      value="0"
      onChange={() => undefined}
    />
    <input
      className="hidden"
      name="daily_report[memo]"
      value=""
      onChange={() => undefined}
    />
  </>
);
