import React, { FC, useState, useMemo, useEffect } from 'react';
import type {
  CascaderValue,
  CascaderOption,
} from 'antd-mobile/es/components/cascader';
import { Cascader } from '@/components/form/dailyReport/Cascader';
import { findCascaderValue, makeCascaderDefaultValue } from '@/utils/cascader';

type WorkItemCascaderProps = {
  delimiter: string;
  options: Array<CascaderOption>;
  items: Array<{ id: number; name: string }>;
  value: { id: number; name: string } | null;
  onChange: (value: { id: number; name: string } | null) => void;
  title?: string;
  placeholder?: string;
  valueLabelOr?: string;
};

export const WorkItemCascader: FC<WorkItemCascaderProps> = ({
  delimiter,
  options,
  items,
  value,
  onChange,
  title,
  placeholder,
  valueLabelOr,
}) => {
  const [item, setItem] = useState<Array<CascaderValue>>(() =>
    makeCascaderDefaultValue(value, delimiter),
  );
  const valueLabel = useMemo(
    () =>
      findCascaderValue(items, item)
        .map((wp) => wp.name)
        .unwrapOr(null),
    [items, item],
  );

  useEffect(() => {
    if (value === null) {
      setItem([]);
    } else {
      setItem(makeCascaderDefaultValue(value, delimiter));
    }
  }, [value]);

  return (
    <Cascader
      options={options}
      value={item}
      valueLabel={valueLabel}
      valueLabelOr={valueLabelOr}
      title={title}
      placeholder={placeholder}
      onConfirm={(newValue) => {
        setItem(newValue);
        const ret = findCascaderValue(items, newValue).unwrapOr(null);
        onChange(ret);
      }}
    />
  );
};
