import type {
  Work,
} from '@/interface';
import { useDailyReportTypeC } from '@/hooks/dailyReport/useDailyReportTypeC';

type UseDailyReportTypeD = {
  defaultValue: Array<Work>;
};

export const useDailyReportTypeD = (props: UseDailyReportTypeD): ReturnType<typeof useDailyReportTypeC> => useDailyReportTypeC(props)
