import { Work, TimeAlias } from '@/interface';

const eqTimespan = (time: TimeAlias, work: Work) => {
  if (time.startAt === work.startAt && time.endAt === work.endAt) {
    return true;
  }

  return false;
};

export const getWorks4Timespan = (
  works: Array<Work>,
  timespanInfo: Array<TimeAlias>,
): Array<Work> => {
  if (works.length === 0) {
    return timespanInfo.map(() => null);
  }

  if (works.length >= timespanInfo.length) {
    return works;
  }

  return timespanInfo.map((time) => {
    const work = works.find((item) => eqTimespan(time, item));
    if (work) {
      return work;
    }

    return null;
  });
};
