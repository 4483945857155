import { useMemo } from 'react';
import { User } from '@/interface';
import { useUsersWithUnsubmit } from '@/hooks/api';

type SelectOptions = Array<{ label: string; value: string }>;

// @params format for yyyy-MM-dd
type UseFetchUnsubmitUsers = {
  date: string;
  users: Array<User>;
};

export const useFetchUnsubmitUsers = ({
  date,
  users,
}: UseFetchUnsubmitUsers) => {
  const { users: fetchUsers, isLoading } = useUsersWithUnsubmit(
    { target: date },
    users,
  );

  const options = useMemo<SelectOptions>(
    () =>
      fetchUsers.map((user) => ({
        label: user.name,
        value: user.id.toString(),
      })),
    [fetchUsers],
  );

  return { options, isLoading };
};
