import React, { FC, ReactNode } from 'react';

interface SectionLayoutProps {
  title: string;
  children: ReactNode;
}

export const SectionLayout: FC<SectionLayoutProps> = (props) => {
  const {
    title,
    children,
  } = props;

  return (
    <div className="l-section">
      <h2 className="section-title">
        <span className="title">{title}</span>
      </h2>
      <div className="section-body">
        {children}
      </div>
    </div>
  );
};
