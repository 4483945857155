import { useCallback, ChangeEvent } from 'react';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import type {
  WorkContent,
  Work,
  NewWorkWithWorkingForm,
  NewWorkWithWorking,
  DispatchReturn,
} from '@/interface';
import { initNewWorkWithWorkingForm } from '@/const';
import { formTypeCMetaDataState } from '@/store/dailyreports/atom';
import { isValidWorkWithWorking } from '@/utils/isValidWork';

type UseDailyReportTypeCControl = {
  worksState: DispatchReturn<Array<Work>>;
  workingWorkState: DispatchReturn<NewWorkWithWorking | null>;
  newWorkState: DispatchReturn<NewWorkWithWorkingForm>;
};

export const useDailyReportTypeCControl = ({
  worksState,
  workingWorkState,
  newWorkState,
}: UseDailyReportTypeCControl) => {
  const { dummyWorkProperty, breaktimeWorkContent } = useRecoilValue(
    formTypeCMetaDataState,
  );
  const [newWork, setNewWork] = newWorkState;
  const [workingWork, setWorkingWork] = workingWorkState;
  const [works, setWorks] = worksState;

  const handleChange = useCallback(
    (value: { id: number; name: string } | null) => {
      const now = DateTime.now();
      const startAt = now.setZone('Asia/Tokyo').toFormat('HH:mm');
      setNewWork({
        ...newWork,
        workContent: value as WorkContent,
        startAt,
      });
    },
    [setNewWork, newWork],
  );

  const handleChangeStartAt = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewWork({
        ...newWork,
        startAt: event.target.value,
      });
    },
    [setNewWork, newWork],
  );

  const handleAdd = useCallback(() => {
    if (!isValidWorkWithWorking(newWork)) {
      return;
    }

    setWorkingWork({
      ...newWork,
      object: 'newWorkWithWorking',
    });
    setNewWork({
      ...initNewWorkWithWorkingForm,
    });

    if (workingWork !== null) {
      setWorks([
        ...works,
        {
          ...workingWork,
          object: undefined,
          endAt: newWork.startAt,
          workProperty: dummyWorkProperty,
          includeRest: false,
        } as Work,
      ]);
    }
  }, [
    setWorks,
    setNewWork,
    newWork,
    workingWork,
    works,
    dummyWorkProperty,
    setWorkingWork,
  ]);

  const handleDelete = useCallback(
    (index: number) => {
      setWorks(works.filter((_, i) => i !== index));
    },
    [setWorks, works],
  );

  const handleClickBreaktime = useCallback(() => {
    const now = DateTime.now();
    const startAt = now.setZone('Asia/Tokyo').toFormat('HH:mm');
    setWorkingWork({
      object: 'newWorkWithWorking',
      type: 'breaktime',
      workContent: breaktimeWorkContent,
      startAt,
    });
    setNewWork({
      ...initNewWorkWithWorkingForm,
    });

    if (workingWork !== null) {
      setWorks([
        ...works,
        {
          ...workingWork,
          object: undefined,
          endAt: startAt,
          workProperty: dummyWorkProperty,
          includeRest: false,
        } as Work,
      ]);
    }
  }, [
    setWorkingWork,
    setNewWork,
    setWorks,
    workingWork,
    works,
    dummyWorkProperty,
    breaktimeWorkContent,
  ]);

  const handleWorkingDelete = useCallback(() => {
    setWorkingWork(null);
  }, [setWorkingWork]);

  const handleSubmit = useCallback(() => {
    if (workingWork !== null) {
      const now = DateTime.now();
      const startAt = now.setZone('Asia/Tokyo').toFormat('HH:mm');
      const updateWorks = [
        ...works,
        {
          ...workingWork,
          object: undefined,
          endAt: startAt,
          workProperty: dummyWorkProperty,
          includeRest: false,
        } as Work,
      ];
      setWorks(updateWorks);
    }
    setWorkingWork(null);
  }, [setWorkingWork, setWorks, works, workingWork]);

  return {
    newWork,
    workingWork,
    works,
    handleChange,
    handleChangeStartAt,
    handleAdd,
    handleDelete,
    handleClickBreaktime,
    handleSubmit,
    handleWorkingDelete,
  };
};
