import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { dailyreportState, useDRDispatch } from '@/store/dailyreports/atom';
import { SectionLayout } from '@/components/SectionLayout';
import { FormGroup } from '@/components/FormGroup';
import DailyReportArigatonaForm from '@/components/DailyReportArigatonaForm';
import { Arigatona, Work, TimeAlias } from '@/interface';
import DailyReportTimespanWorksFormDraftContainer from './DailyReportTimespanWorksFormDraftContainer';

const dialogMessage = `本日の日報を提出しますか？
変更する方は編集画面からおこなってください`;

interface WorkDayProps {
  dailyReportPersisted: boolean;
}

export interface DailyReportTimespanContainerProps extends WorkDayProps {
  dailyReportWorks: Work[];
  dailyReportArigatona: Arigatona;
  thunksComment: string | null;
  memo: string | null;
  timespanInfo: Array<TimeAlias>;
}

const DailyReportTimespanContainer: FC<DailyReportTimespanContainerProps> = (
  props,
) => {
  const {
    dailyReportPersisted,

    dailyReportWorks,

    dailyReportArigatona,
    thunksComment,
    memo,
    timespanInfo,
  } = props;

  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();

  return (
    <>
      <SectionLayout title="1. 作業日時">
        <FormGroup label="日付" required>
          <input
            type="date"
            name="daily_report[date]"
            id="daily_report_date"
            className="form-control"
            readOnly={dailyReportPersisted}
            required
            value={date}
            onChange={setDate}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="2. 報告内容を編集">
        <DailyReportTimespanWorksFormDraftContainer
          dailyReportDate={date}
          dailyReportWorks={dailyReportWorks}
          timespanInfo={timespanInfo}
        />
      </SectionLayout>

      <SectionLayout title="3. ありがとうを送る">
        <FormGroup>
          <DailyReportArigatonaForm
            dailyReportArigatona={dailyReportArigatona}
          />
        </FormGroup>
        <FormGroup>
          <textarea
            name="arigatona[comment]"
            id="arigatona_comment"
            className="form-control"
            rows={3}
            defaultValue={thunksComment}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="4. 連絡事項">
        <FormGroup>
          <textarea
            name="daily_report[memo]"
            id="daily_report_memo"
            className="form-control"
            rows={3}
            defaultValue={memo}
          />
        </FormGroup>
      </SectionLayout>

      <div className="submit-container">
        <input
          type="submit"
          className="btn-primary block rounded"
          value="提出"
          data-confirm={dialogMessage}
        />
      </div>
    </>
  );
};

export default DailyReportTimespanContainer;
