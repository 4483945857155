import { useState } from 'react';
import type { Work, DispatchReturn } from '@/interface';
import { isValidWork } from '@/utils/isValidWork';
import { isTimePeriodValid, isNoOverlapValid } from '@/validators';

const isValid = (works: Array<Work>, work: Work): boolean => {
  const x = isValidWork(work);
  const y = isTimePeriodValid(work);
  const z = isNoOverlapValid(works, work);

  return x && y && z[0];
};

type UseDailyReportTypeAControl = {
  worksState: DispatchReturn<Array<Work>>;
  newWorkState: DispatchReturn<Partial<Work>>;
};

export const useDailyReportTypeAControl = ({
  worksState,
  newWorkState,
}: UseDailyReportTypeAControl) => {
  const [newWork, setNewWork] = newWorkState;
  const [works, setWorks] = worksState;

  const [addEnable, setAddEnable] = useState(() => isValid(works, newWork));

  const handleChange = (work: Work) => {
    setNewWork(work);
    setAddEnable(isValid(works, work));
  };

  const handleAdd = () => {
    setWorks([...works, newWork]);
    // フォームをリセット
    setNewWork({
      id: null,
      workContent: null,
      workProperty: null,
      workDetail: null,
      amount: null,
      startAt: '',
      endAt: '',
      includeRest: false,
    });
    setAddEnable(false);
  };

  const handleDelete = (index: number) => {
    setWorks(works.filter((_, i) => i !== index));
  };

  const handleSubmit = () => undefined;

  return {
    newWork,
    works,
    handleChange,

    handleAdd,
    handleDelete,
    handleSubmit,
    addEnable,
  };
};
