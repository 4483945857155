import React from 'react';
import { useRecoilValue } from 'recoil';
import type { Work, Arigatona } from '@/interface';
import { SectionLayout } from '@/components/SectionLayout';
import { FormGroup } from '@/components/FormGroup';
import DailyReportArigatonaForm from '@/components/DailyReportArigatonaForm';
import {
  dailyreportState,
  useDRDispatch,
  workPropertiesState,
  workPropertiesWithCascadeState,
  workContentsState,
  workContentsWithCascadeState,
  workDetailsState,
} from '@/store/dailyreports/atom';
import { useDailyReportPersist, useDailyReportTypeAControl } from '@/hooks';
import { DailyReportWorksForm } from './DailyReportWorksForm';

const dialogMessage = `本日の日報を提出しますか？
変更する方は編集画面からおこなってください`;

type DailyReportViewTypeAFormProps = {
  readOnly: boolean;
  dailyReportWorks: Array<Work>;
  dailyReportArigatona: Arigatona;
  thunksComment: string | null;
  memo: string | null;
  submitText?: string;
};

export function DailyReportViewTypeAForm({
  readOnly,
  dailyReportWorks,
  dailyReportArigatona,
  thunksComment,
  memo,
  submitText = '提出',
}: DailyReportViewTypeAFormProps) {
  const { date } = useRecoilValue(dailyreportState);
  const { setDate } = useDRDispatch();

  const workProperties = useRecoilValue(workPropertiesState);
  const workPropertyOptions = useRecoilValue(workPropertiesWithCascadeState);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);
  const workDetails = useRecoilValue(workDetailsState);

  const { works: worksState, newWork: newWorkState } = useDailyReportPersist({
    dailyReportDate: date,
    defaultValue: dailyReportWorks,
  });

  const { newWork, works, handleChange, handleAdd, handleDelete, addEnable } =
    useDailyReportTypeAControl({
      worksState,
      newWorkState,
    });

  return (
    <>
      <SectionLayout title="1. 作業日時">
        <FormGroup label="日付" required>
          <input
            type="date"
            name="daily_report[date]"
            id="daily_report_date"
            className="form-control"
            readOnly={readOnly}
            required
            value={date}
            onChange={setDate}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="2. 報告内容を編集">
        <DailyReportWorksForm
          workContents={workContents}
          workContentOptions={workContentOptions}
          workProperties={workProperties}
          workPropertyOptions={workPropertyOptions}
          workDetails={workDetails}
          works={works}
          newWork={newWork}
          handleChangeNewWork={handleChange}
          addEnable={addEnable}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
        />
      </SectionLayout>

      <SectionLayout title="3. ありがとうを送る">
        <FormGroup>
          <DailyReportArigatonaForm
            dailyReportArigatona={dailyReportArigatona}
          />
        </FormGroup>
        <FormGroup>
          <textarea
            name="arigatona[comment]"
            id="arigatona_comment"
            className="form-control"
            rows={3}
            defaultValue={thunksComment}
          />
        </FormGroup>
      </SectionLayout>

      <SectionLayout title="4. 連絡事項">
        <FormGroup>
          <textarea
            name="daily_report[memo]"
            id="daily_report_memo"
            className="form-control"
            rows={3}
            defaultValue={memo}
          />
        </FormGroup>
      </SectionLayout>

      <div className="submit-container">
        <input
          type="submit"
          className="btn-primary block rounded"
          value={submitText}
          data-confirm={dialogMessage}
        />
      </div>
    </>
  );
}
