import { Option, None, Some } from 'ts-results';

export const getValueLabel = (
  isEmpty: boolean,
  placeholder: string,
  valueLabel: string | null,
): Option<string> => {
  if (isEmpty) {
    return Some(placeholder);
  }
  if (valueLabel === null) {
    return None;
  }

  return Some(valueLabel);
};
