import type {
  Work,
} from '@/interface';
import { useDailyReportTypeCPersist } from './useDailyReportTypeCPersist';

type UseDailyReportTypeDPersist = {
  userId: string;
  dailyReportDate: string;
  defaultValue: Work[];
};

export const useDailyReportTypeDPersist = (props: UseDailyReportTypeDPersist): ReturnType<typeof useDailyReportTypeCPersist> => useDailyReportTypeCPersist(props);
