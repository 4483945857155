import { useState } from 'react';

type SelectOptions = Array<{ label: string; value: string }>;

export const useDailyReportRepresentationControl = () => {
  const [selectedUsers, setSelectedUsers] = useState<SelectOptions>([]);

  const handleChangePerson = (value: SelectOptions) => {
    setSelectedUsers(value);
  };

  return { selectedUsers, handleChangePerson };
};
