import React, { FC } from 'react';
import type { CascaderOption } from 'antd-mobile/es/components/cascader';
import type { Work, WorkProperty, WorkContent } from '@/interface';
import { WorkEntity, WorkEntityEmpty } from '@/components/WorkEntity';
import { FormGroup } from '@/components/FormGroup';
import { WorkForm } from '@/components/WorkForm';

export interface IDailyReportWorksForm {
  workContents: WorkContent[];
  workContentOptions: Array<CascaderOption>;
  workProperties: WorkProperty[];
  workPropertyOptions: Array<CascaderOption>;
  workDetails: WorkContent[];
  amount: number;
  works: Work[];
  newWork: Work | null;
  handleChangeNewWork: (work: Work) => void;
  addEnable: boolean;
  handleAdd: () => void; // FIXME
  handleDelete: (index: number) => void;
}

export const DailyReportWorksForm: FC<IDailyReportWorksForm> = (props) => {
  const {
    workContents,
    workContentOptions,
    workProperties,
    workPropertyOptions,
    workDetails,
    works,
    newWork,
    handleChangeNewWork,
    addEnable,
    handleAdd,
    handleDelete,
  } = props;

  return (
    <>
      <WorkForm
        workContents={workContents}
        workContentOptions={workContentOptions}
        workProperties={workProperties}
        workPropertyOptions={workPropertyOptions}
        workDetails={workDetails}
        newWork={newWork}
        handleChangeNewWork={handleChangeNewWork}
      />
      <FormGroup>
        <button
          type="button"
          onClick={handleAdd}
          className="btn-default block"
          disabled={!addEnable}
        >
          <i className="fas fa-plus-circle" /> 追加
        </button>
      </FormGroup>
      {works.length === 0 ? (
        <WorkEntityEmpty />
      ) : (
        works.map((work, index) => (
          <WorkEntity
            key={index}
            work={work}
            handleDelete={handleDelete}
            index={index}
          />
        ))
      )}
    </>
  );
};
