import React, { FC } from 'react';
import cx from 'classnames';

interface Lang {
  overtime: {
    modal: {
      title: string;
      cancel: string;
      submit: string;
    };
  };
}

interface ModalProps {
  I18n: Lang;
  hidden: boolean;
  body: React.Component;
  onCancel: () => void;
  onSubmit: () => void;
}

export const Modal: FC<ModalProps> = ({
  I18n,
  hidden,
  body,
  onCancel,
  onSubmit,
}) => (
  <div
    className={cx(
      'flex justify-center h-screen w-screen items-center bg-gray-50 bg-opacity-50 antialiased',
      {
        hidden,
      },
    )}
  >
    <div className="flex flex-col max-h-screen w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
      <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
        <p className="font-semibold text-gray-800">
          {I18n.overtime.modal.title}
        </p>
      </div>

      <div className="flex flex-col px-6 py-5 bg-gray-50 overflow-y-scroll">
        {body}
      </div>

      <div className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
        <p
          className="font-semibold text-gray-600"
          onClick={onCancel}
          role="presentation"
        >
          {I18n.overtime.modal.cancel}
        </p>
        <button
          type="button"
          className="px-4 py-2 text-white font-semibold bg-blue-500 rounded"
          onClick={onSubmit}
        >
          {I18n.overtime.modal.submit}
        </button>
      </div>
    </div>
  </div>
);
