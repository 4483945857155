import React, { FC, useCallback } from 'react';
import { List, Empty, Space } from 'antd-mobile';
import { EditSFill, MinusCircleOutline } from 'antd-mobile-icons';
import type { NewWorkWithWorking } from '@/interface';

type WorkingWorkContentProps = {
  work: NewWorkWithWorking;
  onClickEdit: (work: NewWorkWithWorking) => void;
  onClickDelete: (work: NewWorkWithWorking) => void;
};

export const WorkingWorkContent: FC<WorkingWorkContentProps> = ({
  work,
  onClickEdit,
  onClickDelete,
}) => {
  const handleClickEdit = useCallback(() => onClickEdit(work), [work]);
  const handleClickDelete = useCallback(() => onClickDelete(work), [work]);

  return (
    <List header="作業中" style={{ '--padding-left': '0' }}>
      {work ? (
        <List.Item
          className="prefix-items-center extra-items-center"
          prefix={
            <button
              type="button"
              className="text-red-600"
              onClick={handleClickDelete}
            >
              <MinusCircleOutline fontSize={24} />
            </button>
          }
          extra={
            <button
              type="button"
              className="text-gray-600"
              onClick={handleClickEdit}
            >
              <EditSFill fontSize={24} />
            </button>
          }
        >
          <Space className="adm-space-item-flex-unset">
            <span className="text-sm">{work.startAt}〜</span>
            <span className="text-sm">{work.workContent.name}</span>
          </Space>
        </List.Item>
      ) : (
        <List.Item
          prefix={<Empty style={{ padding: 0 }} imageStyle={{ width: 32 }} />}
        >
          <span className="text-sm">開始している作業がありません</span>
        </List.Item>
      )}
    </List>
  );
};
