import React, { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Dialog, Toast } from 'antd-mobile';
import type { Work, NewWorkWithWorking, DispatchReturn } from '@/interface';
import { isWork } from '@/utils';
import { isTimePeriodValid } from '@/validators';
import { WorkFormTypeD } from '@/components/form/DailyReportViewTypeD/WorkFormTypeD';
import {
  workContentsState,
  workContentsWithCascadeState,
} from '@/store/dailyreports/atom';
import { formKind } from '@/store/dailyreports-configure';

type UseDailyReportTypeDWorkEditDialogControl = {
  worksState: DispatchReturn<Array<Work>>;
};

export const useDailyReportTypeDWorkEditDialogControl = ({
  worksState,
}: UseDailyReportTypeDWorkEditDialogControl) => {
  const [works, setWorks] = worksState;
  const formKindConfigure = useRecoilValue(formKind);
  const workContents = useRecoilValue(workContentsState);
  const workContentOptions = useRecoilValue(workContentsWithCascadeState);

  const formRef = useRef<{ getWork: () => Work | NewWorkWithWorking }>();

  const handleWorkEdit = useCallback(
    (index: number, work: Work) => {
      Dialog.confirm({
        content: (
          <WorkFormTypeD
            delimiter={formKindConfigure.delimiter}
            workContentOptions={workContentOptions}
            workContents={workContents}
            work={work}
            ref={formRef}
          />
        ),
        cancelText: 'キャンセル',
        confirmText: '変更',
        onConfirm: async () => {
          const v = formRef.current.getWork();
          if (isWork(v) && isTimePeriodValid(v)) {
            const updatedWorks = works.map((x, i) => (i === index ? v : x));
            setWorks(updatedWorks);
          } else {
            Toast.show({
              icon: 'fail',
              content: '終了時刻を開始時刻より後にしてください',
              position: 'top',
            });
            throw new Error('終了時刻を開始時刻より後にしてください');
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setWorks, works],
  );

  return {
    handleWorkEdit,
  };
};
